import { ILogin, ILoginProfile, IStandardAPIResponse } from "../vm";
import { httpClient } from "./UtilService";

export async function loginToApp(
    cred: ILogin
): Promise<IStandardAPIResponse<{ token: string, profile: ILoginProfile }>> {
    try {
        let res = await httpClient<{ token: string, profile: ILoginProfile }>(`admin_login`, 'POST', cred);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}
