import React from "react";
import { ToastContext } from "../common/ToastProvider";
import {
  IBoardDetails,
  IChapterDetails,
  IClassDetails,
  IMediumDetails,
  ISubjectDetails,
} from "../../vm";
import { getAllMediums } from "../../services/MediumServices";
import { getAllBoards, getAllClasses } from "../../services/ClassService";
import { getAllSubjects } from "../../services/SubjectService";
import Loading from "../common/Loading";
import {
  addAChapter,
  deleteAChapter,
  getAllChapters,
  updateAChapter,
} from "../../services/ChapterService";
import "../style.css";
import "./sidebar.css";
import { removeModal } from "../../services/UtilService";
import { Formik, FormikErrors } from "formik";
import NoData from "../common/NoData";

export interface ChapterProps {}

const Chapter: React.FC<ChapterProps> = () => {
  // state
  const [state, setState] = React.useState({
    isLoading: false,
    allBoards: [],
    allMediums: [],
    allClasses: [],
    allSubjects: [],
    chapterObj: {
      subject_id: undefined,
      chapter_name: "",
      chapter_id: undefined,
    },
    board_id: undefined,
    medium_id: undefined,
    class_id: undefined,
    onEdit: false,
    isSidebarOpen: false,
  } as {
    isLoading: boolean;
    allBoards: IBoardDetails[];
    allMediums: IMediumDetails[];
    allClasses: IClassDetails[];
    allSubjects: ISubjectDetails[];
    chapterObj: {
      subject_id: number;
      chapter_name: string;
      chapter_id: number;
    };
    board_id: number;
    medium_id: number;
    class_id: number;
    allChapters: IChapterDetails[];
    onEdit: boolean;
    isSidebarOpen: boolean;
  });

  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  // hooks
  React.useEffect(() => {
    const asyncFunc = async () => {
      await getBoardDetails();
      document.querySelector(".sideBar")?.classList.add("showSideBar");
      document.querySelector("#main_containerin")?.classList.add("ml-300px");
    };
    asyncFunc();
  }, []);

  const getBoardDetails = async () => {
    setState({ ...state, isLoading: true });
    let result = await getAllBoards();
    if (result && result.status) {
      getClassDetails(result.data.getBoard);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting board details",
        "error"
      );
    }
  };

  const getClassDetails = async (allBoards: any) => {
    setState({ ...state, isLoading: true });
    let result = await getAllClasses();
    if (result && result.status) {
      getMediumDetails(allBoards, result.data);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting class details",
        "error"
      );
    }
  };

  const getMediumDetails = async (allBoards: any, allClasses: any) => {
    setState({ ...state, isLoading: true });

    let result = await getAllMediums();
    if (result && result.status) {
      let obj = {
        ...state,
        allBoards,
        allClasses,
        allMediums: result.data.getMedium,
        board_id: allBoards[0].board_id,
        medium_id: result.data.getMedium[0].medium_id,
        class_id: allClasses[0].class_id,
        isLoading: false,
      };
      setState(obj);
      getSubjects(obj);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting medium details",
        "error"
      );
    }
  };

  const getSubjects = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }

    setState({ ...localState, isLoading: true });
    let result = await getAllSubjects({
      board_id: localState.board_id,
      medium_id: localState.medium_id,
      class_id: localState.class_id,
    });
    if (result && result.status) {
      let obj = {
        ...localState,
        allSubjects: result.data !== "" ? result.data : [],
        chapterObj: {
          ...state.chapterObj,
          subject_id: result.data[0]?.subject_id,
        },
        isLoading: false,
      };
      setState(obj);
      if (result.data?.length > 0) {
        getChapters(obj);
      } else {
        showToast("No subject found for this combination", "error");
      }
    } else {
      setState({ ...state, isLoading: false });
      showToast(result?.message || "Error while getting subjects", "error");
    }
  };

  const getChapters = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }

    setState({ ...localState, isLoading: true });
    let result = await getAllChapters({
      board_id: localState.board_id,
      medium_id: localState.medium_id,
      class_id: localState.class_id,
      subject_id: localState.chapterObj.subject_id,
    });
    if (result && result.status) {
      setState({
        ...localState,
        allChapters: result.data?.get_chapters || [],
        isLoading: false,
      });
    } else {
      setState({ ...state, isLoading: false });
      showToast(result?.message || "Error while getting chapters", "error");
    }
  };

  const onSearch = async () => {
    if (state.chapterObj.subject_id === undefined) {
      showToast("Select a subject first", "error");
    }
    await getChapters();
  };

  const handlePreferenceChange = async (e: any) => {
    let obj = {
      ...state,
      [e.target.name]: parseInt(e.target.value),
    };
    setState(obj);

    await getSubjects(obj);
  };

  const onSubjectChange = (e: any) => {
    let obj = {
      ...state,
      chapterObj: {
        ...state.chapterObj,
        subject_id: e.target.value,
      },
    };
    setState(obj);
  };

  const onTextChange = (e: any) => {
    setState({
      ...state,
      chapterObj: {
        ...state.chapterObj,
        chapter_name: e.target.value,
      },
    });
  };

  const onAddChapter = async (values: typeof state.chapterObj) => {
    setState({ ...state, isLoading: true });
    let result;
    if (state.chapterObj.chapter_id != undefined) {
      result = await updateAChapter({
        board_id: state.board_id,
        class_id: state.class_id,
        medium_id: state.medium_id,
        subject_id: values.subject_id,
        chapter_name: values.chapter_name,
        chapter_id: values.chapter_id,
      });
    } else {
      result = await addAChapter({
        board_id: state.board_id,
        class_id: state.class_id,
        medium_id: state.medium_id,
        subject_id: values.subject_id,
        chapter_name: values.chapter_name,
      });
    }
    if (result && result.status) {
      removeModal();
      let allChapters: IChapterDetails[] = [...state.allChapters];
      let index = allChapters.findIndex(
        (chapter) => chapter.chapter_id === result.data.chapter_id
      );
      if (index != -1) {
        allChapters[index] = result.data;
      } else {
        allChapters.unshift(result.data);
      }

      setState({
        ...state,
        allChapters,
        chapterObj: {
          ...state.chapterObj,
          chapter_name: "",
          chapter_id: undefined,
        },
        onEdit: false,
        isLoading: false,
        isSidebarOpen: false,
      });
      showToast(
        `Chapter ${
          state.chapterObj.chapter_id != undefined ? "updated" : "added"
        } successfully`,
        "success"
      );
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message ||
          `Error while ${
            state.chapterObj.chapter_id != undefined ? "updating" : "adding"
          } chapter`,
        "error"
      );
    }
  };

  const onEdit = (chapter: IChapterDetails, index: number) => {
    setState({
      ...state,
      chapterObj: {
        ...state.chapterObj,
        chapter_id: chapter.chapter_id,
        chapter_name: chapter.chapter_name,
      },
      onEdit: true,
      isSidebarOpen: true,
    });
  };

  const onCancelEdit = () => {
    setState({
      ...state,
      chapterObj: {
        ...state.chapterObj,
        chapter_id: undefined,
        chapter_name: "",
      },
      onEdit: false,
    });
  };

  const onDelete = async (chapter_id: number, index: number) => {
    setState({ ...state, isLoading: true });
    let result = await deleteAChapter({ chapter_id: chapter_id });
    if (result && result.status) {
      let allChapters = [...state.allChapters];
      allChapters.splice(index, 1);
      setState({
        ...state,
        allChapters,
        isLoading: false,
      });
      showToast("Chapter deleted successfully", "success");
    } else {
      setState({ ...state, isLoading: false });
      showToast(result?.message || "Error while deleting a chapter", "error");
    }
  };

  return (
    <React.Fragment>
      {state.isLoading && <Loading />}
      <div className="contentColm" style={{ marginTop: 80 }}>
        <div className="row space-between">
          <div>
            <h4 style={{ marginTop: 6 }} className="text-bold">
              Chapters
            </h4>
          </div>
          <div>
            <div className="contmid">
              <button
                id="startsession"
                className="finishBtn"
                type="button"
                data-toggle="modal"
                data-target="#myModal"
                onClick={() =>
                  setState({
                    ...state,
                    chapterObj: {
                      ...state.chapterObj,
                      chapter_id: undefined,
                      chapter_name: "",
                    },
                    isSidebarOpen: true,
                  })
                }
              >
                Add Chapter
              </button>
            </div>
          </div>
        </div>

        <div
          className="row card-shadow space-around p-16"
          style={{ marginTop: 30 }}
        >
          <div className="col-sm-2">
            <div className="form-group">
              <label>Board</label>
              <select
                className="form-control"
                onChange={handlePreferenceChange}
                value={state.board_id}
                name="board_id"
              >
                {state.allBoards &&
                  state.allBoards.map((board) => {
                    return (
                      <option key={board.board_id} value={board.board_id}>
                        {board.board_name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label>Medium</label>
              <select
                className="form-control"
                // id=""
                onChange={handlePreferenceChange}
                value={state.medium_id}
                name="medium_id"
              >
                {state.allMediums &&
                  state.allMediums.map((medium) => {
                    return (
                      <option key={medium.medium_id} value={medium.medium_id}>
                        {medium.medium}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label>Class</label>
              <select
                className="form-control"
                // id=""
                onChange={handlePreferenceChange}
                value={state.class_id}
                name="class_id"
              >
                {state.allClasses &&
                  state.allClasses.map((cls) => {
                    return (
                      <option key={cls.class_id} value={cls.class_id}>
                        {"Class " + cls.class_name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label>Subject</label>
              <select
                className="form-control"
                onChange={onSubjectChange}
                value={state.chapterObj.subject_id}
              >
                {state.allSubjects && state.allSubjects.length > 0 ? (
                  state.allSubjects.map((sub) => {
                    return (
                      <option key={sub.subject_id} value={sub.subject_id}>
                        {sub.subject}
                      </option>
                    );
                  })
                ) : (
                  <option>No subjects</option>
                )}
              </select>
            </div>
          </div>
          <div className="col-sm-2">
            <button
              id="startsession"
              className="btn btn-outline-warning"
              type="button"
              style={{ paddingLeft: 30, paddingRight: 30, marginTop: 30 }}
              onClick={onSearch}
            >
              Search
            </button>
          </div>
        </div>

        <div className="row card-shadow" style={{ marginTop: 50 }}>
          <div className="col-sm-12 card-shadow">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Chapter Name</th>
                  <th scope="col" className="text-right" align="right">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.allChapters && state.allChapters.length > 0 ? (
                  state.allChapters.map((chapter, index: number) => {
                    return (
                      <tr key={index}>
                        <td width={300}>{chapter.chapter_name}</td>
                        <td width={200} align="right">
                          <span
                            onClick={() => onEdit(chapter, index)}
                            data-toggle="modal"
                            data-target="#myModal"
                            title="Edit"
                            className="pencil-icon"
                          >
                            <i className="fa fa-pencil"></i>
                          </span>
                          <span
                            onClick={() => onDelete(chapter.chapter_id, index)}
                            title="Delete"
                            className="trash-icon"
                          >
                            <i className="fa fa-trash"></i>
                          </span>
                          {/* <button
                            type="button"
                            className="btn btn-primary btn-sm mr-20"
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => onEdit(chapter, index)}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => onDelete(chapter.chapter_id, index)}
                          >
                            Delete
                          </button> */}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={2} className="not-found">
                      {/* No chapters for this combination */}
                      <NoData msg="No chapters for this combination" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {state.isSidebarOpen && (
          <div
            className="modal right fade"
            id="myModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myModalLabel"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myModalLabel2">
                    {state.chapterObj.chapter_id != undefined
                      ? "Update Chapter"
                      : "Add Chapter"}
                  </h4>
                  <button
                    type="button"
                    className="close"
                    // data-dismiss="modal"
                    // aria-label="Close"
                    onClick={() => {
                      removeModal();
                      setState({
                        ...state,
                        chapterObj: {
                          ...state.chapterObj,
                          chapter_id: undefined,
                          chapter_name: "",
                        },
                        isSidebarOpen: false,
                      });
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <Formik
                    enableReinitialize
                    initialValues={state.chapterObj}
                    validate={(values) => {
                      let errors: FormikErrors<typeof state.chapterObj> = {};
                      if (!values.chapter_name) {
                        errors.chapter_name = "This field is required";
                      }
                      if (!values.subject_id) {
                        errors.subject_id = "This field is required";
                      }
                      return errors;
                    }}
                    onSubmit={async (
                      values: typeof state.chapterObj,
                      { setSubmitting }
                    ) => {
                      await onAddChapter(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleSubmit,
                      values,
                      handleChange,
                      handleBlur,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label>Board</label>
                              <select
                                className="form-control"
                                onChange={handlePreferenceChange}
                                value={state.board_id}
                                name="board_id"
                              >
                                {state.allBoards &&
                                  state.allBoards.map((board) => {
                                    return (
                                      <option
                                        key={board.board_id}
                                        value={board.board_id}
                                      >
                                        {board.board_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label>Medium</label>
                              <select
                                className="form-control"
                                onChange={handlePreferenceChange}
                                value={state.medium_id}
                                name="medium_id"
                              >
                                {state.allMediums &&
                                  state.allMediums.map((medium) => {
                                    return (
                                      <option
                                        key={medium.medium_id}
                                        value={medium.medium_id}
                                      >
                                        {medium.medium}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label>Class</label>
                              <select
                                className="form-control"
                                onChange={handlePreferenceChange}
                                value={state.class_id}
                                name="class_id"
                              >
                                {state.allClasses &&
                                  state.allClasses.map((cls) => {
                                    return (
                                      <option
                                        key={cls.class_id}
                                        value={cls.class_id}
                                      >
                                        {"Class " + cls.class_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label>Subject</label>
                              <select
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.subject_id}
                                name="subject_id"
                              >
                                {state.allSubjects &&
                                state.allSubjects.length > 0 ? (
                                  state.allSubjects.map((sub) => {
                                    return (
                                      <option
                                        key={sub.subject_id}
                                        value={sub.subject_id}
                                      >
                                        {sub.subject}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option>No subjects</option>
                                )}
                              </select>
                              {(errors.subject_id &&
                                touched.subject_id &&
                                errors.subject_id) ||
                                ""}
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <label>Chapter Name</label>
                            <input
                              type="text"
                              name="chapter_name"
                              value={values.chapter_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              // placeholder="Name of the Chapter"
                            />
                            {(errors.chapter_name &&
                              touched.chapter_name &&
                              errors.chapter_name) ||
                              ""}
                          </div>
                          <div className="col-sm-12">
                            <button
                              id="startsession"
                              className="submitBtn"
                              type="submit"
                              // data-dismiss="modal"
                              // onClick={() => onAddChapter()}
                            >
                              {state.chapterObj.chapter_id != undefined
                                ? "Update"
                                : "Add"}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Chapter;
