import React from "react";
import { ToastContext } from "../common/ToastProvider";
import {
  IBoardDetails,
  IClassDetails,
  IMediumDetails,
  ISubjectDetails,
} from "../../vm";
import { getAllMediums } from "../../services/MediumServices";
import { getAllBoards, getAllClasses } from "../../services/ClassService";
import { getAllSubjects } from "../../services/SubjectService";
import Loading from "../common/Loading";
import "../style.css";
import NoData from "../common/NoData";

export interface SubjectsProps {}

const Subjects: React.FC<SubjectsProps> = () => {
  // state
  const [state, setState] = React.useState({
    isLoading: false,
    allBoards: [],
    allMediums: [],
    allClasses: [],
    board_id: undefined,
    medium_id: undefined,
    class_id: undefined,
    allSubjects: [],
  } as {
    isLoading: boolean;
    allBoards: IBoardDetails[];
    allMediums: IMediumDetails[];
    allClasses: IClassDetails[];
    board_id: number;
    medium_id: number;
    class_id: number;
    allSubjects: ISubjectDetails[];
  });

  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  // hooks
  React.useEffect(() => {
    const asyncFunc = async () => {
      await getBoardDetails();
      document.querySelector(".sideBar")?.classList.add("showSideBar");
      document.querySelector("#main_containerin")?.classList.add("ml-300px");
    };
    asyncFunc();
  }, []);

  const getBoardDetails = async () => {
    setState({ ...state, isLoading: true });
    let result = await getAllBoards();
    if (result && result.status) {
      getClassDetails(result.data.getBoard);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting board details",
        "error"
      );
    }
  };

  const getClassDetails = async (allBoards: any) => {
    setState({ ...state, isLoading: true });
    let result = await getAllClasses();
    if (result && result.status) {
      getMediumDetails(allBoards, result.data);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting class details",
        "error"
      );
    }
  };

  const getMediumDetails = async (allBoards: any, allClasses: any) => {
    setState({ ...state, isLoading: true });

    let result = await getAllMediums();
    if (result && result.status) {
      let obj = {
        ...state,
        allBoards,
        allClasses,
        allMediums: result.data.getMedium,
        board_id: allBoards[0].board_id,
        medium_id: result.data.getMedium[0].medium_id,
        class_id: allClasses[0].class_id,
        isLoading: false,
      };
      setState(obj);
      getSubjects(obj);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting medium details",
        "error"
      );
    }
  };

  const getSubjects = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }

    console.log("state", localState);

    setState({ ...localState, isLoading: true });
    let result = await getAllSubjects({
      board_id: localState.board_id,
      medium_id: localState.medium_id,
      class_id: localState.class_id,
    });
    if (result && result.status) {
      setState({
        ...localState,
        allSubjects: result.data,
        isLoading: false,
      });
    } else {
      setState({ ...state, isLoading: false });
      showToast(result?.message || "Error while getting subjects", "error");
    }
  };

  const handleChange = async (e: any) => {
    let obj = {
      ...state,
      [e.target.name]: parseInt(e.target.value),
    };
    setState(obj);

    // await getSubjects(obj);
  };

  const onSearch = async () => {
    if (state.board_id === undefined) {
      showToast("Select a board", "error");
    }
    if (state.medium_id === undefined) {
      showToast("Select a medium", "error");
    }
    if (state.class_id === undefined) {
      showToast("Select a class", "error");
    }
    await getSubjects();
  };

  return (
    <React.Fragment>
      {state.isLoading && <Loading />}
      <div className="contentColm" style={{ marginTop: 80 }}>
        <div className="row">
          <div>
            <h4 style={{ marginTop: 6 }} className="text-bold">
              Subjects
            </h4>
          </div>
        </div>
        <div
          className="row card-shadow space-around p-16"
          style={{ marginTop: 30 }}
        >
          <div className="col-sm-3">
            <div className="form-group">
              <label>Board</label>
              <select
                className="form-control"
                // id=""
                onChange={handleChange}
                value={state.board_id}
                name="board_id"
              >
                {state.allBoards &&
                  state.allBoards.map((board) => {
                    return (
                      <option key={board.board_id} value={board.board_id}>
                        {board.board_name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label>Medium</label>
              <select
                className="form-control"
                // id=""
                onChange={handleChange}
                value={state.medium_id}
                name="medium_id"
              >
                {state.allMediums &&
                  state.allMediums.map((medium) => {
                    return (
                      <option key={medium.medium_id} value={medium.medium_id}>
                        {medium.medium}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label>Class</label>
              <select
                className="form-control"
                // id=""
                onChange={handleChange}
                value={state.class_id}
                name="class_id"
              >
                {state.allClasses &&
                  state.allClasses.map((cls) => {
                    return (
                      <option key={cls.class_id} value={cls.class_id}>
                        {"Class " + cls.class_name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-sm-2">
            <button
              id="startsession"
              className="btn btn-outline-warning"
              type="button"
              style={{ paddingLeft: 30, paddingRight: 30, marginTop: 30 }}
              onClick={onSearch}
            >
              Search
            </button>
          </div>
        </div>
        <div className="row" style={{ marginTop: 50 }}>
          <div className="col-sm-12 card-shadow">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th className="text-right" align="right">
                    Subject Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.allSubjects && state.allSubjects.length > 0 ? (
                  state.allSubjects.map((sub, index) => {
                    return (
                      <tr key={index}>
                        <td width={150}>{index + 1}</td>
                        <td width={300} align="right">
                          {sub.subject}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={2} className="not-found">
                      {/* No subjects for this combination */}
                      <NoData msg="No subjects for this combination" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Subjects;
