import React from "react";
import { Formik, FormikErrors } from "formik";
import { getAllBoards, getAllClasses } from "../../services/ClassService";
import { getAllMediums } from "../../services/MediumServices";
import {
  createUser,
  getAllUsers,
  updateUser,
  resetUserPassword,
} from "../../services/UserService";
import { isValidEmail, removeModal } from "../../services/UtilService";
import {
  IBoardDetails,
  IClassDetails,
  IMediumDetails,
  IResetPassword,
  IUserDetails,
} from "../../vm";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import { ROLE_DICT } from "../../Constant";
import NoData from "../common/NoData";
declare var $;

export interface UsersProps {}

const Users: React.FC<UsersProps> = () => {
  // state
  const [state, setState] = React.useState({
    isLoading: false,
    userObj: {
      id: undefined,
      name: "",
      email: "",
      role: "student",
    },
    passwordObj: {
      id: undefined,
      current_password: undefined,
      password: undefined,
      verify_password: undefined,
    },
    allUsers: [],
    isSidebarOpen: false,
    isOnEdit: false,
    isOnResetPassword: false,
    // allBoards: [],
    // allClasses: [],
    // allMediums: [],
  } as {
    isLoading: boolean;
    userObj: IUserDetails;
    passwordObj: IResetPassword;
    // user_id: number;
    allUsers: IUserDetails[];
    isSidebarOpen: boolean;
    isOnEdit: boolean;
    isOnResetPassword: boolean;
    // allBoards: IBoardDetails[];
    // allClasses: IClassDetails[];
    // allMediums: IMediumDetails[];
  });

  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  // hooks
  React.useEffect(() => {
    const asyncFunc = async () => {
      await getUsers();
      document.querySelector(".sideBar")?.classList.add("showSideBar");
      document.querySelector("#main_containerin")?.classList.add("ml-300px");
    };
    asyncFunc();
  }, []);

  // const getBoardDetails = async () => {
  //   setState({ ...state, isLoading: true });
  //   let result = await getAllBoards();
  //   if (result && result.status) {
  //     getClassDetails(result.data.getBoard);
  //   } else {
  //     setState({ ...state, isLoading: false });
  //     showToast(
  //       result?.message || "Error whiling getting board details",
  //       "error"
  //     );
  //   }
  // };

  // const getClassDetails = async (allBoards: any) => {
  //   setState({ ...state, isLoading: true });
  //   let result = await getAllClasses();
  //   if (result && result.status) {
  //     getMediumDetails(allBoards, result.data);
  //   } else {
  //     setState({ ...state, isLoading: false });
  //     showToast(
  //       result?.message || "Error whiling getting class details",
  //       "error"
  //     );
  //   }
  // };

  // const getMediumDetails = async (allBoards: any, allClasses: any) => {
  //   setState({ ...state, isLoading: true });

  //   let result = await getAllMediums();
  //   if (result && result.status) {
  //     let obj = {
  //       ...state,
  //       allBoards,
  //       allClasses,
  //       allMediums: result.data.getMedium,
  //       isLoading: false,
  //     };
  //     setState(obj);
  //     getUsers(obj);
  //   } else {
  //     setState({ ...state, isLoading: false });
  //     showToast(
  //       result?.message || "Error whiling getting medium details",
  //       "error"
  //     );
  //   }
  // };

  const getUsers = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }

    setState({ ...localState, isLoading: true });
    let result = await getAllUsers();
    if (result && result.status) {
      setState({
        ...localState,
        allUsers: result.data.getUsers,
        isLoading: false,
      });
    } else {
      setState({ ...state, isLoading: false });
      showToast(result?.message || "Error while getting users", "error");
    }
  };

  const onEdit = (user: IUserDetails, index: number) => {
    setState({
      ...state,
      userObj: {
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role,
      },
      isSidebarOpen: true,
      isOnEdit: true,
    });
  };

  const onResetPassword = (user: IUserDetails) => {
    setState({
      ...state,
      isOnResetPassword: true,
      isSidebarOpen: true,
      passwordObj: { ...state.passwordObj, id: user.id },
    });
  };

  return (
    <React.Fragment>
      {state.isLoading && <Loading />}
      <div className="contentColm" style={{ marginTop: 80 }}>
        <div className="row space-between">
          <div>
            <h4 style={{ marginTop: 6 }} className="text-bold">
              All Users
            </h4>
          </div>
          <div>
            <div className="contmid">
              <button
                id="startsession"
                className="finishBtn"
                type="button"
                data-toggle="modal"
                data-target="#myModal"
                onClick={() =>
                  setState({
                    ...state,
                    userObj: {
                      id: undefined,
                      name: "",
                      email: "",
                      role: "Student",
                    },
                    isSidebarOpen: true,
                  })
                }
              >
                Add User
              </button>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-sm-12 card-shadow">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col" className="text-right" align="right">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.allUsers && state.allUsers.length > 0 ? (
                  state.allUsers.map((user, index: number) => {
                    return (
                      <tr key={index}>
                        <td width={150}>{user.name}</td>
                        <td width={100}>{user.email}</td>
                        <td width={100}>{ROLE_DICT[user.role]}</td>
                        <td width={150} align="right">
                          <span
                            onClick={() => onEdit(user, index)}
                            data-toggle="modal"
                            data-target="#myModal"
                            title="Edit"
                            className="pencil-icon"
                          >
                            <i className="fa fa-pencil"></i>
                          </span>
                          <span
                            onClick={() => onResetPassword(user)}
                            data-toggle="modal"
                            data-target="#myModal"
                            title="Reset Password"
                            className="lock-icon"
                          >
                            <i className="fa fa-lock"></i>
                          </span>
                          {/* <button
                            type="button"
                            className="btn btn-primary btn-sm mr-20"
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => onEdit(user, index)}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="btn btn-success btn-sm"
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => onResetPassword(user)}
                          >
                            Reset Password
                          </button> */}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} className="not-found">
                      {/* No Users found */}
                      <NoData msg="No Users found" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {state.isSidebarOpen && (
          <div
            className="modal right fade show"
            id="myModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myModalLabel"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  {state.isOnResetPassword ? (
                    <h4 className="modal-title" id="myModalLabel2">
                      Reset Password
                    </h4>
                  ) : (
                    <h4 className="modal-title" id="myModalLabel2">
                      {state.userObj.id != undefined
                        ? "Update User"
                        : "Add User"}
                    </h4>
                  )}
                  <button
                    type="button"
                    className="close"
                    // data-dismiss="modal"
                    // aria-label="Close"
                    onClick={() => {
                      removeModal();
                      setState({
                        ...state,
                        userObj: {
                          id: undefined,
                          name: "",
                          email: "",
                          role: "",
                        },
                        isSidebarOpen: false,
                        isOnEdit: false,
                        isOnResetPassword: false,
                      });
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                {!state.isOnResetPassword ? (
                  <div className="modal-body">
                    <Formik
                      enableReinitialize
                      initialValues={state.userObj}
                      validate={(values) => {
                        let errors: FormikErrors<IUserDetails> = {};
                        if (!values?.name?.trim()) {
                          errors.name = "This field is required";
                        }
                        if (!values?.email?.trim()) {
                          errors.email = "This field is required";
                        } else if (!isValidEmail(values.email)) {
                          errors.email = "Please enter valid email";
                        }
                        // if (!values.password) {
                        //   errors.password = "Required";
                        // } else if (values.password < 4) {
                        //   errors.password =
                        //     "Needs to be more than 4 characters";
                        // }
                        // if (!values.password_confirmation) {
                        //   errors.password_confirmation = "Required";
                        // } else if (
                        //   values.password_confirmation !== values.password
                        // ) {
                        //   errors.password_confirmation =
                        //     "Password doesn't match";
                        // }
                        return errors;
                      }}
                      onSubmit={async (
                        values: IUserDetails,
                        { setSubmitting }
                      ) => {
                        let obj = { ...values };

                        let result;
                        setState({ ...state, isLoading: true });
                        if (state.userObj.id !== undefined) {
                          result = await updateUser({
                            id: obj.id,
                            name: obj.name.trim(),
                            email: obj.email.trim(),
                          });
                        } else {
                          result = await createUser({
                            name: obj.name.trim(),
                            email: obj.email.trim(),
                            role: obj.role.toLowerCase(),
                            password: obj.password.trim(),
                            password_confirmation: obj.password_confirmation.trim(),
                          });
                        }
                        if (result && result.status) {
                          removeModal();
                          let allUsers: IUserDetails[] = [...state.allUsers];
                          let index = allUsers.findIndex(
                            (user) => user.id === result.data.id
                          );
                          if (index != -1) {
                            allUsers[index] = result.data;
                          } else {
                            allUsers.unshift(result.data);
                          }
                          setState({
                            ...state,
                            allUsers,
                            isLoading: false,
                            isSidebarOpen: false,
                          });
                          showToast(
                            `User ${
                              state.userObj.id != undefined
                                ? "updated"
                                : "added"
                            } successfully`,
                            "success"
                          );
                        } else {
                          setState({ ...state, isLoading: false });
                          showToast(
                            result?.message ||
                              `Error while ${
                                state.userObj.id != undefined
                                  ? "updating"
                                  : "adding"
                              } chapter`,
                            "error"
                          );
                        }
                        setSubmitting(false);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        handleSubmit,
                        values,
                        handleChange,
                        handleBlur,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                  type="text"
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="form-control"
                                  // placeholder="Name of the User"
                                />
                                {(errors.name && touched.name && errors.name) ||
                                  ""}
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="text"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="form-control"
                                  // placeholder=""
                                />
                                {(errors.email &&
                                  touched.email &&
                                  errors.email) ||
                                  ""}
                              </div>
                            </div>
                            {!state.isOnEdit && (
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Role</label>
                                  <select
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.role}
                                    name="role"
                                  >
                                    <option value="student">Student</option>
                                    <option value="admin">Admin</option>
                                    <option value="educator">Educator</option>
                                  </select>
                                  {(errors.role &&
                                    touched.role &&
                                    errors.role) ||
                                    ""}
                                </div>
                              </div>
                            )}
                            {!state.isOnEdit && (
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Password</label>
                                  <input
                                    type="text"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    // placeholder=""
                                  />
                                  {(errors.password &&
                                    touched.password &&
                                    errors.password) ||
                                    ""}
                                </div>
                              </div>
                            )}
                            {!state.isOnEdit && (
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Confirm Password</label>
                                  <input
                                    type="text"
                                    name="password_confirmation"
                                    value={values.password_confirmation}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    // placeholder=""
                                  />
                                  {(errors.role &&
                                    touched.role &&
                                    errors.role) ||
                                    ""}
                                </div>
                              </div>
                            )}
                            <div className="col-sm-12">
                              <button
                                id="startsession"
                                className="submitBtn"
                                type="submit"
                                // data-dismiss="modal"
                                // onClick={() => onAddClass()}
                              >
                                {state.userObj.id != undefined
                                  ? "Update"
                                  : "Add"}
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                ) : (
                  <div className="modal-body">
                    <Formik
                      enableReinitialize
                      initialValues={state.passwordObj}
                      validate={(values) => {
                        let errors: FormikErrors<IResetPassword> = {};
                        if (!values?.current_password?.trim()) {
                          errors.current_password = "This field is required";
                        }
                        if (!values?.password?.trim()) {
                          errors.password = "This field is required";
                        } else if (values?.password?.trim().length < 4) {
                          errors.password =
                            "Needs to be more than 4 characters";
                        }
                        if (!values?.verify_password?.trim()) {
                          errors.verify_password = "This field is required";
                        } else if (values.verify_password !== values.password) {
                          errors.verify_password = "Password doesn't match";
                        }
                        return errors;
                      }}
                      onSubmit={async (
                        values: IResetPassword,
                        { setSubmitting }
                      ) => {
                        let obj = { ...values };
                        obj.current_password = obj.current_password.trim();
                        obj.password = obj.password.trim();
                        obj.verify_password = obj.verify_password.trim();
                        setState({ ...state, isLoading: true });
                        let result = await resetUserPassword(obj);
                        if (result && result.status) {
                          removeModal();
                          setState({
                            ...state,
                            isLoading: false,
                            isSidebarOpen: false,
                            isOnResetPassword: false,
                          });
                          showToast(`Password updated successfully`, "success");
                        } else {
                          setState({ ...state, isLoading: false });
                          showToast(
                            result?.message || `Error while updating password`,
                            "error"
                          );
                        }
                        setSubmitting(false);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        handleSubmit,
                        values,
                        handleChange,
                        handleBlur,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label htmlFor="name">Current Password</label>
                                <input
                                  type="text"
                                  name="current_password"
                                  value={values.current_password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="form-control"
                                  // placeholder="Name of the User"
                                />
                                {(errors.current_password &&
                                  touched.current_password &&
                                  errors.current_password) ||
                                  ""}
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>New Password</label>
                                <input
                                  type="text"
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="form-control"
                                  // placeholder=""
                                />
                                {(errors.password &&
                                  touched.password &&
                                  errors.password) ||
                                  ""}
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>Confirm Password</label>
                                <input
                                  type="text"
                                  name="verify_password"
                                  value={values.verify_password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="form-control"
                                  // placeholder=""
                                />
                                {(errors.verify_password &&
                                  touched.verify_password &&
                                  errors.verify_password) ||
                                  ""}
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <button
                                id="startsession"
                                className="submitBtn"
                                type="submit"
                                // data-dismiss="modal"
                                // onClick={() => onAddClass()}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                )}

                {/* </div> */}

                {/* <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      value={state.classObj.class_name}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Name of the Class"
                    />
                  </div>
                  <div className="col-sm-12">
                    <button
                      id="startsession"
                      className="submitBtn"
                      type="button"
                      data-dismiss="modal"
                      onClick={() => onAddClass()}
                    >
                      {state.user_id != undefined ? "Update" : "Add"}
                    </button>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Users;
