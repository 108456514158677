import React from "react";
import { Link } from "react-router-dom";
import { parseJwt } from "../../services/UtilService";
import { IChapterDetails, IClassDetails, IVideosDetails } from "../../vm";
import "../style.css";

export interface ViewLessonProps {
  videoObj: IVideosDetails;
  handleShowVideoPage: any;
  // educatorObj: any;
  // chapters: IChapterDetails[];
  chapterDict: any;
  chapterId: number;
  // classes: IClassDetails[];
  classDict: any;
  classId: number;
  isPublished: boolean;
  onPublishLesson?: any;
  boardDict: any;
  boardId: number;
  mediumDict: any;
  mediumId: number;
  allSubjects: any;
  subjectId: number;
}

const ViewLesson: React.FC<ViewLessonProps> = ({
  videoObj,
  handleShowVideoPage,
  // educatorObj,
  // chapters,
  chapterDict,
  chapterId,
  // className,
  classId,
  // classes,
  classDict,
  isPublished,
  onPublishLesson,
  boardDict,
  boardId,
  mediumDict,
  mediumId,
  allSubjects,
  subjectId,
}) => {
  const token = parseJwt();
  return (
    <React.Fragment>
      <div className="contentColm" style={{ marginTop: 80 }}>
        <div className="row space-between">
          <button onClick={handleShowVideoPage} className="backtBtn">
            Back
          </button>
          {isPublished && (token?.role === "Admin" || token?.role === "admin") && (
            <button onClick={onPublishLesson} className="publishBtn">
              Publish
            </button>
          )}
        </div>
        <div className="lessonClm">
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src={`https://player.vimeo.com/video/${videoObj.video_url}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <h5 className="text-bold">
            <Link to={`https://vimeo.com/${videoObj.video_url}`}>
              {videoObj?.video_title || ""}
              {/* Class{" "} */}
              {/* {classes &&
                classes.find((c) => c.class_id === classId)?.class_name} */}
              {/* {classDict && classDict[classId]} -{" "} */}
              {/* {chapters &&
                chapters.find((c) => c.chapter_id === chapterId)
                  ?.chapter_name} */}
              {/* {chapterDict && chapterDict[chapterId]} */}
              {/* - {educatorObj.educatorName} */}
            </Link>
          </h5>
          <div className="tagsLink-bold">
            {boardDict && <p>{boardDict[boardId]}</p>}
            {mediumDict && <p>{mediumDict[mediumId]}</p>}
            {classDict && <p>Class {classDict[classId]}</p>}
            {allSubjects && (
              <p>
                {
                  allSubjects.find((s: any) => s.subject_id === subjectId)
                    ?.subject
                }
              </p>
            )}
            {chapterDict && <p>{chapterDict[chapterId]}</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-9">
            <div className="post_detail">
              {/* <h5 className="teacher_profile">
                  <img
                    src={
                      educatorObj.educatorImageUrl
                        ? educatorObj.educatorImageUrl
                        : "/images/teacher.jpg"
                    }
                    alt=""
                  />
                  {"  "}
                  {educatorObj.educatorName}
                </h5> */}
              <p>{videoObj.description}</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewLesson;
