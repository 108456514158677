//prod
// export const API_URL = 'http://sgapi.lexyslabs.com/api/';

//old dev
// export const API_URL = 'http://167.71.230.254/gurukul/public/api/';

// local
// export const API_URL = 'http://localhost:9000/http://167.71.230.254/gurukul/public/api/';

// new dev
export const API_URL = "https://api.starguru.org/api/";

export const VIMEO_CREDENTIALS = {
  CLIENT_ID: "96e684d2ac638e82c8d3d75700941a60d8af9650",
  CLIENT_SECRET:
    "dW8iaGKM4A6HB1tzJA1OraYK/yxs7RU1lWpYLwRiqOc7iI342ZBwTdKtyeSgzgynRAUvzuJmp2iWKFmKJ9tJhCA1jLNC7qRBSPHBP35JASNq82KuN2JvdIn+ZG8q/EJ7",
  TOKEN: "a110432f5beb18527376bb6c5a634e9b", // "20fe4d5218952fd2e6af0c9b0f672d9f",
};

export const LANGUAGES_DICT: { [Key: string]: string } = {
  "1": "English",
  "2": "తెలుగు",
  "3": "اردو",
  "4": "हिन्दी",
  "5": "ಕನ್ನಡ",
  "6": "বাংলা",
  "7": "தமிழ்",
  "8": "मराठी",
};

export const ROLE_DICT: { [Key: string]: string } = {
  student: "Student",
  educator: "Educator",
  admin: "Admin",
};
