import { Formik, FormikErrors } from "formik";
import React from "react";
import {
  addAMedium,
  updateAMedium,
  getAllMediums,
  deleteAMedium,
} from "../../services/MediumServices";
import { removeModal } from "../../services/UtilService";
import { IMediumDetails } from "../../vm";
import Loading from "../common/Loading";
import NoData from "../common/NoData";
import { ToastContext } from "../common/ToastProvider";
import "../style.css";
import "./sidebar.css";

export interface MediumsProps {}

const Mediums: React.FC<MediumsProps> = () => {
  // state
  const [state, setState] = React.useState({
    isLoading: false,
    mediumObj: {
      medium: "",
      medium_id: undefined,
    },
    allMediums: [],
    isSidebarOpen: false,
  } as {
    isLoading: boolean;
    mediumObj: IMediumDetails;
    allMediums: Array<IMediumDetails>;
    isSidebarOpen: boolean;
  });

  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  // hooks
  React.useEffect(() => {
    const asyncFunc = async () => {
      await getMediums();
      document.querySelector(".sideBar")?.classList.add("showSideBar");
      document.querySelector("#main_containerin")?.classList.add("ml-300px");
    };
    asyncFunc();
  }, []);

  const getMediums = async () => {
    setState({ ...state, isLoading: true });
    let result = await getAllMediums();
    if (result && result.status) {
      setState({
        ...state,
        allMediums: result.data.getMedium,
        isLoading: false,
      });
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error while getting medium details",
        "error"
      );
    }
  };

  const handleChange = (e: any) => {
    setState({
      ...state,
      mediumObj: {
        ...state.mediumObj,
        medium: e.target.value,
      },
    });
  };

  const onAddMedium = async (medium_name: string, medium_id: number) => {
    setState({ ...state, isLoading: true });
    let result;
    if (state.mediumObj.medium_id != undefined) {
      result = await updateAMedium({
        medium: medium_name,
        medium_id: medium_id,
      });
    } else {
      result = await addAMedium({ medium: medium_name });
    }
    if (result && result.status_code) {
      removeModal();
      let allMediums: IMediumDetails[] = [...state.allMediums];
      let index = allMediums.findIndex(
        (cls) => cls.medium_id === result.data.medium_id
      );
      if (index != -1) {
        allMediums[index] = result.data;
      } else {
        allMediums.unshift(result.data);
      }

      setState({
        ...state,
        allMediums,
        mediumObj: {
          medium: "",
          medium_id: undefined,
        },
        isSidebarOpen: false,
        isLoading: false,
      });
      showToast(
        `Medium ${
          state.mediumObj.medium_id != undefined ? "updated" : "added"
        } successfully`,
        "success"
      );
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message ||
          `Error while ${
            state.mediumObj.medium_id != undefined ? "updating" : "adding"
          } medium`,
        "error"
      );
    }
  };

  const onEdit = (medium: IMediumDetails, index: number) => {
    setState({
      ...state,
      mediumObj: {
        medium_id: medium.medium_id,
        medium: medium.medium,
      },
      isSidebarOpen: true,
    });
  };

  const onDelete = async (medium_id: number, index: number) => {
    setState({ ...state, isLoading: true });
    let result = await deleteAMedium({ medium_id: medium_id });
    if (result && result.status_code) {
      let allMediums = [...state.allMediums];
      allMediums.splice(index, 1);
      setState({
        ...state,
        allMediums,
        isLoading: false,
      });
      showToast("Medium deleted successfully", "success");
    } else {
      setState({ ...state, isLoading: false });
      showToast(result?.message || "Error while deleting a medium", "error");
    }
  };
  return (
    <React.Fragment>
      {state.isLoading && <Loading />}
      <div className="contentColm" style={{ marginTop: 80 }}>
        <div className="row space-between">
          <div>
            <h4 style={{ marginTop: 6 }} className="text-bold">
              Mediums
            </h4>
          </div>
          <div>
            <div className="contmid">
              <button
                id="startsession"
                className="finishBtn"
                type="button"
                data-toggle="modal"
                data-target="#myModal"
                onClick={() =>
                  setState({
                    ...state,
                    mediumObj: {
                      medium_id: undefined,
                      medium: "",
                    },
                    isSidebarOpen: true,
                  })
                }
              >
                Add Medium
              </button>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-sm-12 card-shadow">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Medium Name</th>
                  <th scope="col" className="text-right" align="right">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.allMediums && state.allMediums.length > 0 ? (
                  state.allMediums.map((medium, index: number) => {
                    return (
                      <tr key={index}>
                        <td width={300}>{medium.medium}</td>
                        <td width={200} align="right">
                          <span
                            onClick={() => onEdit(medium, index)}
                            data-toggle="modal"
                            data-target="#myModal"
                            title="Edit"
                            className="pencil-icon"
                          >
                            <i className="fa fa-pencil"></i>
                          </span>
                          <span
                            onClick={() => onDelete(medium.medium_id, index)}
                            title="Delete"
                            className="trash-icon"
                          >
                            <i className="fa fa-trash"></i>
                          </span>
                          {/* <button
                            type="button"
                            className="btn btn-primary btn-sm mr-20"
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => onEdit(medium, index)}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => onDelete(medium.medium_id, index)}
                          >
                            Delete
                          </button> */}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={2} className="not-found">
                      {/* No mediums found */}
                      <NoData msg="No mediums found" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {state.isSidebarOpen && (
          <div
            className="modal right fade"
            id="myModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myModalLabel"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myModalLabel2">
                    {state.mediumObj.medium_id != undefined
                      ? "Update Medium"
                      : "Add Medium"}
                  </h4>
                  <button
                    type="button"
                    className="close"
                    // data-dismiss="modal"
                    // aria-label="Close"
                    onClick={() => {
                      removeModal();
                      setState({
                        ...state,
                        mediumObj: {
                          medium: "",
                          medium_id: undefined,
                        },
                        isSidebarOpen: false,
                      });
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <Formik
                    enableReinitialize
                    initialValues={state.mediumObj}
                    validate={(values) => {
                      let errors: FormikErrors<IMediumDetails> = {};
                      if (!values.medium) {
                        errors.medium = "This field is required";
                      }
                      return errors;
                    }}
                    onSubmit={async (
                      values: IMediumDetails,
                      { setSubmitting }
                    ) => {
                      await onAddMedium(values.medium, values.medium_id);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleSubmit,
                      values,
                      handleChange,
                      handleBlur,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Medium Name</label>
                            <input
                              type="text"
                              name="medium"
                              value={values.medium}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              // placeholder="Name of the Medium"
                            />
                            {(errors.medium &&
                              touched.medium &&
                              errors.medium) ||
                              ""}
                          </div>
                          <div className="col-sm-12">
                            <button
                              id="startsession"
                              className="submitBtn"
                              type="submit"
                              // data-dismiss="modal"
                              // onClick={() => onAddMedium()}
                            >
                              {state.mediumObj.medium_id != undefined
                                ? "Update"
                                : "Add"}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Mediums;
