import * as React from "react";
import { VIMEO_CREDENTIALS } from "../../Constant";
import { ToastContext } from "../common/ToastProvider";
var Vimeo = require("vimeo").Vimeo;

export interface UploadMainProps {}

const UploadMain: React.FC<UploadMainProps> = () => {
  const { showToast } = React.useContext(ToastContext);
  const uploadVideo = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      if (file.type.indexOf("video/") === 0) {
        var client = new Vimeo(
          VIMEO_CREDENTIALS.CLIENT_ID,
          VIMEO_CREDENTIALS.CLIENT_SECRET,
          VIMEO_CREDENTIALS.TOKEN
        );

        client.upload(
          file,
          {},
          function (videoId) {
            /// this videoId needs to be used for showing in iframe and send it to API in video_url for storing in DB
            console.log("File upload completed. Your Vimeo URI is:", videoId);
          },
          function (bytesUploaded, bytesTotal) {
            var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            console.log(bytesUploaded, bytesTotal, percentage + "%");
          },
          function (error) {
            console.log("Failed because: " + error);
          }
        );
      } else {
        showToast("Please select video only", "error");
      }
    }
  };

  return (
    <React.Fragment>
      <div>
        <h2>Upload</h2>
        <input type="file" onChange={uploadVideo} />
        {/* https://vimeo.com/videos/480867717

https://vimeo.com/videos/480870939 */}
        <iframe
          className="embed-responsive-item"
          src={`https://player.vimeo.com/video/480870939`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </React.Fragment>
  );
};

export default UploadMain;
