import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { AuthenticatedRoute } from "./components/common/AuthenticatedRoute";
import { AuthenticatedRouteAdmin } from "./components/common/AuthenticatedRouteAdmin";
import DashboardMain from "./components/dashboard/DashboardMain";
import LoginMain from "./components/login/LoginMain";
import Classes from "./components/preferences/Classes";
import Mediums from "./components/preferences/Mediums";
import Subjects from "./components/preferences/Subjects";
import Chapters from "./components/preferences/Chapter";
import UploadMain from "./components/upload/UploadMain";
import Lessons from "./components/preferences/Lessons";
import Users from "./components/preferences/Users";
import UnpublishedVideos from "./components/preferences/UnpublishedVideos";

export interface RoutesMainProps {}

const RoutesMain: React.FC<RoutesMainProps> = () => {
  return (
    <React.Fragment>
      <main>
        <Switch>
          <Route path="/" exact component={LoginMain} />
          <Route path="/upload" exact component={UploadMain} />
          {/* <AuthenticatedRoute
            path="/dashboard"
            exact
            component={DashboardMain}
          /> */}
          <AuthenticatedRouteAdmin path="/classes" exact component={Classes} />
          <AuthenticatedRouteAdmin path="/mediums" exact component={Mediums} />
          <AuthenticatedRouteAdmin
            path="/subjects"
            exact
            component={Subjects}
          />
          <AuthenticatedRouteAdmin
            path="/chapters"
            exact
            component={Chapters}
          />
          <AuthenticatedRoute path="/lessons" exact component={Lessons} />
          <AuthenticatedRouteAdmin path="/users" exact component={Users} />
          <AuthenticatedRoute
            path="/unpublished-lessons"
            exact
            component={UnpublishedVideos}
          />
        </Switch>
      </main>
    </React.Fragment>
  );
};

export default RoutesMain;
