import React from "react";
import {
  IBoardDetails,
  IChapterDetails,
  IClassDetails,
  IEducatorDetails,
  ILessonDetails,
  IMediumDetails,
  ISubjectDetails,
  IVideosDetails,
} from "../../vm";
import { getAllMediums } from "../../services/MediumServices";
import { getAllBoards, getAllClasses } from "../../services/ClassService";
import { getAllSubjects } from "../../services/SubjectService";
import Loading from "../common/Loading";
import { getAllChapters } from "../../services/ChapterService";
import "../style.css";
import {
  getAllLessons,
  getAllVideos,
  publishALesson,
} from "../../services/LessonService";
import ViewLesson from "./ViewLesson";
import { ToastContext } from "../common/ToastProvider";
import { parseJwt } from "../../services/UtilService";
import NoData from "../common/NoData";

export interface UnpublishedVideosProps {}

const UnpublishedVideos: React.FC<UnpublishedVideosProps> = () => {
  // state
  const [file, setFile] = React.useState(undefined);
  const [state, setState] = React.useState({
    isLoading: false,
    allBoards: [],
    allMediums: [],
    allClasses: [],
    board_id: undefined,
    medium_id: undefined,
    class_id: undefined,
    allSubjects: [],
    subject_id: undefined,
    chapter_id: undefined,
    allChapters: [],
    onEdit: false,
    educators: [],
    lessons: [],
    educator_id: undefined,
    lessonObj: {
      lesson_name: "",
      lesson_id: undefined,
      description: "",
      languages: [],
    },
    showVideoPage: false,
    lesson: {},
    video_url: "",
    isSidebarOpen: false,
    boardDict: {},
    classDict: {},
    mediumDict: {},
    subjectDict: {},
    chapterDict: {},
  } as {
    isLoading: boolean;
    allBoards: IBoardDetails[];
    allMediums: IMediumDetails[];
    allClasses: IClassDetails[];
    board_id: number;
    medium_id: number;
    class_id: number;
    allSubjects: ISubjectDetails[];
    subject_id: number;
    chapter_id: number;
    allChapters: IChapterDetails[];
    onEdit: boolean;
    educators: IEducatorDetails[];
    lessons: IVideosDetails[];
    educator_id: number;
    lessonObj: {
      lesson_name: string;
      lesson_id: number;
      description: string;
      languages: string[];
    };
    showVideoPage: boolean;
    video_url: string;
    lesson: IVideosDetails;
    isSidebarOpen: boolean;
    boardDict: any;
    classDict: any;
    mediumDict: any;
    subjectDict: any;
    chapterDict: any;
  });

  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  // decoded token
  const preference = parseJwt();

  // hooks
  React.useEffect(() => {
    const asyncFunc = async () => {
      await getBoardDetails();
      document.querySelector(".sideBar")?.classList.add("showSideBar");
      document.querySelector("#main_containerin")?.classList.add("ml-300px");
    };
    asyncFunc();
  }, []);

  const getBoardDetails = async () => {
    setState({ ...state, isLoading: true });
    let result = await getAllBoards();
    if (result && result.status) {
      getClassDetails(result.data.getBoard);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting board details",
        "error"
      );
    }
  };

  const getClassDetails = async (allBoards: any) => {
    setState({ ...state, isLoading: true });
    let result = await getAllClasses();
    if (result && result.status) {
      getMediumDetails(allBoards, result.data);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting class details",
        "error"
      );
    }
  };

  const getMediumDetails = async (allBoards: any, allClasses: any) => {
    setState({ ...state, isLoading: true });

    let result = await getAllMediums();
    if (result && result.status) {
      let boardDict = {};
      let classDict = {};
      let mediumDict = {};
      allBoards?.forEach((b: any) => {
        boardDict[b.board_id] = b.board_name;
      });
      allClasses?.forEach((c: any) => {
        classDict[c.class_id] = c.class_name;
      });
      result.data?.getMedium?.forEach((m: any) => {
        mediumDict[m.medium_id] = m.medium;
      });

      let obj = {
        ...state,
        allBoards,
        allClasses,
        allMediums: result.data.getMedium,
        board_id: allBoards[0].board_id,
        medium_id: result.data.getMedium[0].medium_id,
        class_id: allClasses[0].class_id,
        boardDict,
        classDict,
        mediumDict,
        isLoading: false,
      };
      setState(obj);
      getSubjects(obj);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting medium details",
        "error"
      );
    }
  };

  const getSubjects = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }

    setState({ ...localState, isLoading: true });
    let result = await getAllSubjects({
      board_id: localState.board_id,
      medium_id: localState.medium_id,
      class_id: localState.class_id,
    });
    if (result && result.status) {
      // let subjectDict = {};
      // result.data !== ""
      //   ? result.data
      //   : [].forEach((s: any) => {
      //       subjectDict[s.subject_id] = s.subject;
      //     });
      // let obj;
      if (result.data?.length > 0) {
        let obj = {
          ...localState,
          allSubjects: result.data !== "" ? result.data : [],
          subject_id: result.data[0]?.subject_id,
          // subjectDict,
          isLoading: false,
        };
        setState(obj);
        getChapters(obj);
      } else {
        let obj = {
          ...localState,
          allSubjects: [],
          subject_id: undefined,
          allChapters: [],
          chapter_id: undefined,
          educators: [],
          educator_id: undefined,
          lessons: [],
          isLoading: false,
        };
        setState(obj);
        showToast("No subjects for this combination", "error");
      }
      // setState(obj);
    } else {
      setState({ ...localState, isLoading: false });
      showToast(result?.message || "Error while getting subjects", "error");
    }
  };

  const getChapters = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }

    setState({ ...localState, isLoading: true });
    let result = await getAllChapters({
      board_id: localState.board_id,
      medium_id: localState.medium_id,
      class_id: localState.class_id,
      subject_id: localState.subject_id,
    });
    if (result && result.status) {
      // let obj;
      if (result.data?.get_chapters.length > 0) {
        let chapterDict = {};
        result?.data.get_chapters.forEach((ch) => {
          chapterDict[ch.chapter_id] = ch.chapter_name;
        });
        let obj = {
          ...localState,
          allChapters: result.data.get_chapters || [],
          chapter_id: result.data.get_chapters[0]?.chapter_id,
          chapterDict,
          isLoading: false,
        };
        setState(obj);
        getLessons(obj);
      } else {
        let obj = {
          ...localState,
          allChapters: [],
          chapter_id: undefined,
          educators: [],
          educator_id: undefined,
          lessons: [],
          isLoading: false,
        };
        setState(obj);
        showToast("No chapters for this combination", "error");
      }
      // setState(obj);
    } else {
      setState({ ...localState, isLoading: false });
      showToast(result?.message || "Error while getting chapters", "error");
    }
  };

  const getLessons = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }
    setState({ ...localState, isLoading: true });
    let result = await getAllLessons({
      board_id: localState.board_id,
      medium_id: localState.medium_id,
      class_id: localState.class_id,
      subject_id: localState.subject_id,
      chapter_id: localState.chapter_id,
    });
    if (result && result.status) {
      let obj = {
        ...localState,
        educators: result.data?.educators_content
          ? result.data?.educators_content
          : [],
        educator_id: result.data?.educators_content
          ? result.data.educators_content[0]?.id
          : undefined,
        isLoading: false,
      };
      setState(obj);
      //   if (result.data?.educators_content?.length > 0) {
      //     getVideos(obj);
      //   } else {
      //     showToast("No educator found found", "error");
      //   }
      getVideos(obj);
    } else {
      setState({ ...localState, isLoading: false });
      showToast(result?.message || "Error while getting educators", "error");
    }
  };

  const getVideos = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }
    setState({ ...localState, isLoading: true });
    let result = await getAllVideos({
      chapter_id: localState.chapter_id,
      educator_id:
        preference.role === "Admin" ? localState.educator_id : preference.id,
    });
    if (result && result.status) {
      setState({
        ...localState,
        lessons: result.data || [],
        isLoading: false,
      });
    } else {
      setState({ ...localState, isLoading: false });
      showToast(result?.message || "Error while getting lessons", "error");
    }
  };

  const handlePreferenceChange = async (e: any) => {
    let obj = {
      ...state,
      [e.target.name]: parseInt(e.target.value),
    };
    setState(obj);

    await getSubjects(obj);
  };

  const onSubjectChange = async (e: any) => {
    let obj = {
      ...state,
      subject_id: parseInt(e.target.value),
    };
    setState(obj);
    await getChapters(obj);
  };

  const onChapterChange = async (e: any) => {
    let obj = {
      ...state,
      chapter_id: parseInt(e.target.value),
    };
    setState(obj);
    await getLessons(obj);
  };

  const onEducatorChange = async (e: any) => {
    setState({
      ...state,
      educator_id: parseInt(e.target.value),
    });
  };

  const onSearch = async () => {
    if (state.subject_id === undefined) {
      showToast("Select a subject", "error");
    }
    if (state.chapter_id === undefined) {
      showToast("Select a chapter", "error");
    }
    await getVideos();
  };

  const showVideoPage = (
    lesson: IVideosDetails
    // educatorName: string,
    // educatorImageUrl: string
  ) => {
    setState({
      ...state,
      showVideoPage: true,
      lesson,
    });
  };

  const handleShowVideoPage = () => {
    setState({ ...state, showVideoPage: false });
  };

  const onPublishLesson = async () => {
    setState({ ...state, isLoading: true });
    let result = await publishALesson({ lesson_id: state.lesson.lesson_id });
    if (result && result.status) {
      let lessons = [...state.lessons];
      let index = lessons.findIndex(
        (l) => l.lesson_id === state.lesson.lesson_id
      );
      if (index !== -1 || index !== undefined) {
        lessons.splice(index, 1);
      }
      setState({
        ...state,
        lessons,
        isLoading: false,
      });
      showToast("Lesson published successfully", "success");
    } else {
      setState({ ...state, isLoading: false });
      showToast(result?.message || "Error while publish a lesson", "error");
    }
  };

  let publishedLesson: any;

  return (
    <React.Fragment>
      {state.isLoading && <Loading />}
      {!state.showVideoPage && (
        <div className="contentColm" style={{ marginTop: 80 }}>
          <div className="row">
            <div>
              <h4 style={{ marginTop: 6 }} className="text-bold">
                Unpublished Lessons
              </h4>
            </div>
          </div>

          <div
            className="row card-shadow-pb-10 space-around p-16"
            style={{ marginTop: 30 }}
          >
            <div className="col-sm-4">
              <div className="form-group">
                <label>Board</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={handlePreferenceChange}
                  value={state.board_id}
                  name="board_id"
                >
                  {state.allBoards &&
                    state.allBoards.map((board) => {
                      return (
                        <option key={board.board_id} value={board.board_id}>
                          {board.board_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Medium</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={handlePreferenceChange}
                  value={state.medium_id}
                  name="medium_id"
                >
                  {state.allMediums &&
                    state.allMediums.map((medium) => {
                      return (
                        <option key={medium.medium_id} value={medium.medium_id}>
                          {medium.medium}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Class</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={handlePreferenceChange}
                  value={state.class_id}
                  name="class_id"
                >
                  {state.allClasses &&
                    state.allClasses.map((cls) => {
                      return (
                        <option key={cls.class_id} value={cls.class_id}>
                          {"Class " + cls.class_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <label>Subject</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={onSubjectChange}
                  value={state.subject_id}
                >
                  {state.allSubjects && state.allSubjects.length > 0 ? (
                    state.allSubjects.map((sub) => {
                      return (
                        <option key={sub.subject_id} value={sub.subject_id}>
                          {sub.subject}
                        </option>
                      );
                    })
                  ) : (
                    <option>No subjects</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Chapter</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={onChapterChange}
                  value={state.chapter_id}
                >
                  {state.allChapters && state.allChapters.length > 0 ? (
                    state.allChapters.map((chapter) => {
                      return (
                        <option
                          key={chapter.chapter_id}
                          value={chapter.chapter_id}
                        >
                          {chapter.chapter_name}
                        </option>
                      );
                    })
                  ) : (
                    <option>No chapters</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Educator</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={onEducatorChange}
                  value={state.educator_id}
                >
                  {preference.role === "Admin" ? (
                    state.educators && state.educators.length > 0 ? (
                      state.educators.map((edu) => {
                        return (
                          <option key={edu.id} value={edu.id}>
                            {edu.name}
                          </option>
                        );
                      })
                    ) : (
                      <option>No educators</option>
                    )
                  ) : (
                    <option value={preference.id}>{preference.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-sm-2" style={{ marginTop: 5 }}>
              <button
                // id="startsession"
                className="btn btn-outline-warning"
                type="button"
                style={{ paddingLeft: 30, paddingRight: 30 }}
                onClick={onSearch}
              >
                Search
              </button>
            </div>
          </div>

          {state.lessons && state.lessons.length > 0 ? (
            ((publishedLesson = state.lessons.filter(
              (l) => l.ispublished === null
            )),
            publishedLesson?.length > 0 ? (
              publishedLesson?.map((lesson: IVideosDetails) => {
                return (
                  <div
                    className="vidoDetailClm"
                    style={{ marginTop: 30, cursor: "pointer" }}
                    onClick={() => showVideoPage(lesson)}
                  >
                    <div className="row">
                      <div className="col-sm-3">
                        <img
                          src={
                            lesson?.thumbnail
                              ? lesson.thumbnail
                              : "/images/thumb1.jpg"
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm-7">
                        <h5>{lesson?.name || "Default Title"}</h5>
                        <div className="tagsLink">
                          {state.allBoards && (
                            <p>
                              {
                                // state.allBoards.find(
                                //   (b) => b.board_id === state.board_id
                                // )?.board_name
                                state.boardDict[state.board_id]
                              }
                            </p>
                          )}
                          {state.allMediums && (
                            <p>
                              {
                                // state.allMediums.find(
                                //   (m) => m.medium_id === state.medium_id
                                // )?.medium
                                state.mediumDict[state.medium_id]
                              }
                            </p>
                          )}
                          {state.allClasses && (
                            <p>
                              Class{" "}
                              {
                                // state.allClasses.find(
                                //   (c) => c.class_id === state.class_id
                                // )?.class_name
                                state.classDict[state.class_id]
                              }
                            </p>
                          )}
                          {state.allSubjects && (
                            <p>
                              {
                                state.allSubjects.find(
                                  (sub) => sub.subject_id === state.subject_id
                                )?.subject
                                // state.subjectDict[state.subject_id]
                              }
                            </p>
                          )}
                          {state.allChapters && (
                            <p>
                              {
                                // state.allChapters.find(
                                //   (c) => c.chapter_id === state.chapter_id
                                // )?.chapter_name
                                state.chapterDict[state.chapter_id]
                              }
                            </p>
                          )}
                        </div>
                        <p>{lesson.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className="row card-shadow"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 30,
                }}
              >
                {/* <h5>No unpublished lessons for this combination</h5> */}
                <NoData msg="No unpublished lessons for this combination" />
              </div>
            ))
          ) : (
            <div
              className="row card-shadow"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              {/* <h5>No unpublished lessons for this combination</h5> */}
              <NoData msg="No unpublished lessons for this combination" />
            </div>
          )}
        </div>
      )}
      {state.showVideoPage && (
        <ViewLesson
          videoObj={state.lesson}
          // educatorObj={state.educatorObj}
          handleShowVideoPage={handleShowVideoPage}
          boardDict={state.boardDict}
          boardId={state.board_id}
          mediumDict={state.mediumDict}
          mediumId={state.medium_id}
          allSubjects={state.allSubjects}
          subjectId={state.subject_id}
          // chapters={state.allChapters}
          chapterDict={state.chapterDict}
          chapterId={state.chapter_id}
          // classes={state.allClasses}
          classDict={state.classDict}
          classId={state.class_id}
          isPublished={true}
          onPublishLesson={onPublishLesson}
        />
      )}
    </React.Fragment>
  );
};

export default UnpublishedVideos;
