import { ILessonDetails, IStandardAPIResponse, IVideosDetails } from "../vm";
import { httpClient } from "./UtilService";

export async function getAllLessons(data: {
     board_id: number,
    class_id: number,
    medium_id: number,
    subject_id: number,
    chapter_id: number,
}): Promise<IStandardAPIResponse<ILessonDetails>> {
    try {
        let res = await httpClient<ILessonDetails>(`chapter_details`, 'POST', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getAllVideos(data: {
    chapter_id: number,
    educator_id?: number,
}): Promise<IStandardAPIResponse<Array<IVideosDetails>>> {
    try {
        let res = await httpClient<Array<IVideosDetails>>(`get_videos`, 'GET', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function addALesson(data: { 
    lesson: string,
    board_id: number,
    class_id: number,
    medium_id: number,
    subject_id: number,
    chapter_id: number,
    description: string,
    languages: string[],
    keywords?: string[],
    video_url: any
}
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`add_lesson`, 'POST', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function updateALesson(data: { 
    lesson_id: number,
    lesson: string,
    board_id: number,
    class_id: number,
    medium_id: number,
    subject_id: number,
    chapter_id: number,
    description: string,
    languages: string[],
    keywords?: string[],
    video_url: any,
}
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`update_lessons`, 'PATCH', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function publishALesson(data: { 
    lesson_id: number,
}
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`publish_lessons`, 'PATCH', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}