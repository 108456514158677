import { IStandardAPIResponse, IUserDetails, IResetPassword } from "../vm";
import { httpClient } from "./UtilService";

export async function getAllUsers(): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`getUsers`, 'GET');
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function createUser(data: IUserDetails): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`createUser`, 'POST', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function updateUser(data: IUserDetails): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`edit_users`, 'PATCH', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function resetUserPassword(data: IResetPassword): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`reset_password`, 'POST', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}