import * as React from "react";
import { Formik, FormikErrors } from "formik";
import { useHistory } from "react-router-dom";

import "./login.css";
import { ILogin } from "../../vm";
import { loginToApp } from "../../services/LoginService";
import {
  isTokenExpired,
  isValidEmail,
  setProfile,
  setToken,
} from "../../services/UtilService";
import { ToastContext } from "../common/ToastProvider";
import { ProfileContext } from "../common/ProfileProvider";
import Loading from "../common/Loading";
export interface LoginMainProps {}

const LoginMain: React.FC<LoginMainProps> = (props) => {
  const history = useHistory();
  const [loadingState, setLoadingState] = React.useState({
    isLoading: false,
    loadingText: "",
  });
  if (!isTokenExpired()) {
    history.push("/lessons");
  }

  const { showToast } = React.useContext(ToastContext);
  const { functionToUpdate } = React.useContext(ProfileContext);

  return (
    <React.Fragment>
      {loadingState.isLoading && (
        <Loading loadingText={loadingState.loadingText} />
      )}
      <div className="login-container">
        <div className="login-form">
          <div className="login-form-container">
            <div className="logo-container">
              <img src="/images/logo.png" alt="starguru" />
            </div>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validate={(values) => {
                let errors: FormikErrors<ILogin> = {};
                if (!values?.email?.trim()) {
                  errors.email = "Required";
                } else if (!isValidEmail(values.email)) {
                  errors.email = "Please enter valid email";
                }
                if (!values?.password?.trim()) {
                  errors.password = "Required";
                } else if (values?.password?.trim().length <= 4) {
                  errors.password = "Needs to be more than 4 characters";
                }
                return errors;
              }}
              onSubmit={async (values: ILogin, { setSubmitting }) => {
                setLoadingState({
                  isLoading: true,
                  loadingText: "",
                });
                let result = await loginToApp(values);
                setLoadingState({
                  isLoading: false,
                  loadingText: "",
                });
                if (result) {
                  if (result && result.status) {
                    setToken(result.data.token);
                    setProfile(result.data.profile);
                    functionToUpdate(result.data.token);
                    history.push("/lessons");
                  } else {
                    showToast(result.message || "Error while login", "error");
                  }
                }
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input
                      type="email"
                      name="email"
                      value={values.email}
                      className="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      onChange={handleChange}
                      // required
                    />
                    {(errors.email && touched.email && errors.email) || ""}
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      name="password"
                      value={values.password}
                      className="form-control"
                      id="password"
                      placeholder="Password"
                      onChange={handleChange}
                      // required
                    />
                    {(errors.password && touched.password && errors.password) ||
                      ""}
                  </div>
                  <button type="submit" id="sigInBtn" className="btn">
                    Sign in
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginMain;
