import * as React from "react";
import { isTokenExpired, parseJwt } from "../../services/UtilService";
import { withRouter, Link } from "react-router-dom";
import "./navbar.css";

export interface NavBarProps {}

const NavBar: React.FC<NavBarProps> = (props) => {
  // state
  const [state, setState] = React.useState({
    showSidebar: true,
  } as {
    showSidebar: boolean;
  });

  const hasTokeExpired = isTokenExpired();
  const preference = parseJwt();

  const showSidebar = () => {
    document.querySelector(".sideBar")?.classList.add("showSideBar");
    document.querySelector("#main_containerin")?.classList.add("ml-300px");
    setState({ ...state, showSidebar: !state.showSidebar });
  };

  const hideSidebar = () => {
    document.querySelector(".sideBar")?.classList.remove("showSideBar");
    document.querySelector("#main_containerin")?.classList.remove("ml-300px");
    setState({ ...state, showSidebar: !state.showSidebar });
  };

  return (
    <React.Fragment>
      {!hasTokeExpired && (
        <div id="head" className="header">
          <div className="row">
            <div className="col-sm-6">
              {state.showSidebar ? (
                <div className="sideBarBtnClose" onClick={hideSidebar}>
                  <img
                    src="/images/less-than.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              ) : (
                <div className="sideBarBtn" onClick={showSidebar}>
                  <img
                    src="/images/icon-bar.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              )}
              <div className="logo">
                <Link to="/lessons">
                  <img
                    className="img-fluid"
                    src="/images/logo.png"
                    alt="starguru"
                  />
                </Link>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="navi">
                <div className="menu">
                  <ul>
                    {/* <li>
                          <Link to="">
                            Teacher Comments<span className="notifTip">5</span>
                          </Link>
                        </li> */}
                    <li className="profileNav">
                      {preference?.name || "Shravan"}
                      <img src={"/images/teacher.png"} alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withRouter(NavBar);
