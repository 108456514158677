import { IChapter, IChapterDetails, IStandardAPIResponse } from "../vm";
import { httpClient } from "./UtilService";

export async function getAllChapters(data: {
  board_id: number;
  class_id: number;
  medium_id: number;
  subject_id: number;
}): Promise<IStandardAPIResponse<IChapter>> {
  try {
    let res = await httpClient<IChapter>(`get_chapters`, "GET", data);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function addAChapter(data: {
  board_id: number;
  class_id: number;
  medium_id: number;
  subject_id: number;
  chapter_name: string;
}): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`add_chapter`, "PUT", data);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function updateAChapter(data: {
  board_id: number;
  class_id: number;
  medium_id: number;
  subject_id: number;
  chapter_name: string;
  chapter_id: number;
}): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`edit_chapter`, "PATCH", data);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function deleteAChapter(data: {
  chapter_id: number;
}): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`remove_chapter`, "DELETE", data);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
