import { IStandardAPIResponse } from "../vm";
import { httpClient } from "./UtilService";

export async function getAllSubjects(data: {
  board_id: number;
  medium_id: number;
  class_id: number;
}): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`get_subjects`, "GET", data);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
