import {  IClassDetails, IStandardAPIResponse } from "../vm";
import { httpClient } from "./UtilService";

export async function getAllClasses(): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`get_class`, 'GET');
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function addAClass(
    data: { class_name: string }
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`create_class`, 'PUT', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function updateAClass(
    data: IClassDetails
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`update_class`, 'PATCH', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function deleteAClass(
    data: { class_id: number }
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`delete_class`, 'DELETE', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getAllBoards(): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`get_board`, 'GET');
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}