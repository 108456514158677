import React from "react";
import { Link, useLocation } from "react-router-dom";
import { parseJwt, removeToken } from "../../services/UtilService";
import "../style.css";
import { ProfileContext } from "../common/ProfileProvider";

export interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = () => {
  const location = useLocation();

  const [selectedItem, setSelectedItem] = React.useState(location.pathname);
  let token: any;
  token = parseJwt();
  const { preference } = React.useContext(ProfileContext);
  console.log("prrr", preference);

  const onLogout = () => {
    document.querySelector(".sideBar")?.classList.remove("showSideBar");
    document.querySelector("#main_containerin")?.classList.remove("ml-300px");
    removeToken();
  };

  if (selectedItem !== location.pathname) {
    setSelectedItem(location.pathname);
  }

  React.useEffect(() => {
    // token = parseJwt();
  }, [preference?.role, window.location.pathname]);

  const onSelectTab = (endUrl: string) => {
    setSelectedItem(endUrl);
  };

  return (
    <React.Fragment>
      {token && (
        <div className="sideBar">
          <div className="profileDetail">
            <div className="picDet">
              <img src={"/images/teacher.png"} alt="" className="img-fluid" />
              <h5>{token?.name || "Shravan"}</h5>
            </div>
            <div className="stdntDetails">
              <ul>
                <li onClick={() => onSelectTab("/lessons")}>
                  <Link to="/lessons">
                    <span
                      className={selectedItem === "/lessons" ? "activeTab" : ""}
                    >
                      Approved Lessons
                    </span>
                  </Link>
                </li>
                <li onClick={() => onSelectTab("/unpublished-lessons")}>
                  <Link to="/unpublished-lessons">
                    <span
                      className={
                        selectedItem === "/unpublished-lessons"
                          ? "activeTab"
                          : ""
                      }
                    >
                      Lessons for Review
                    </span>
                  </Link>
                </li>
                {(token?.role === "Admin" || token?.role === "admin") && (
                  <li onClick={() => onSelectTab("/classes")}>
                    <Link to="/classes">
                      <span
                        className={
                          selectedItem === "/classes" ? "activeTab" : ""
                        }
                      >
                        Classes
                      </span>
                    </Link>
                  </li>
                )}
                {(token?.role === "Admin" || token?.role === "admin") && (
                  <li onClick={() => onSelectTab("/mediums")}>
                    <Link to="/mediums">
                      <span
                        className={
                          selectedItem === "/mediums" ? "activeTab" : ""
                        }
                      >
                        Mediums
                      </span>
                    </Link>
                  </li>
                )}
                {(token?.role === "Admin" || token?.role === "admin") && (
                  <li onClick={() => onSelectTab("/subjects")}>
                    <Link to="/subjects">
                      <span
                        className={
                          selectedItem === "/subjects" ? "activeTab" : ""
                        }
                      >
                        Subjects
                      </span>
                    </Link>
                  </li>
                )}
                {(token?.role === "Admin" || token?.role === "admin") && (
                  <li onClick={() => onSelectTab("/chapters")}>
                    <Link to="/chapters">
                      <span
                        className={
                          selectedItem === "/chapters" ? "activeTab" : ""
                        }
                      >
                        Chapters
                      </span>
                    </Link>
                  </li>
                )}
                {(token?.role === "Admin" || token?.role === "admin") && (
                  <li onClick={() => onSelectTab("/users")}>
                    <Link to="/users">
                      <span
                        className={selectedItem === "/users" ? "activeTab" : ""}
                      >
                        Users
                      </span>
                    </Link>
                  </li>
                )}
              </ul>

              <ul>
                <li onClick={onLogout}>
                  <Link to="/">
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
