import { API_URL } from "../Constant";
import { ILoginProfile, IStandardAPIResponse } from "../vm";
declare var  $;
export const isValidEmail = (email?: string) => {
  if (!email.trim()) return false;
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const setToken = (token: string) => {
  localStorage.setItem("sriGuruAdminToken", token);
};
export const setProfile = (profile: ILoginProfile) => {
  localStorage.setItem("sriGuruAdminProfile", JSON.stringify(profile));
};
export const removeToken = () => {
  localStorage.removeItem("sriGuruAdminToken");
};

export function getToken() {
  let res = localStorage.getItem("sriGuruAdminToken");
  if (res === null || res === undefined) {
    return "";
  }
  return res;
}

export const parseJwt = (tokenParsed?: string) => {
  let token;
  if (!tokenParsed) {
    token = getToken();
  } else {
    token = tokenParsed;
  }
  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }
  return undefined;
};

export const httpClient = async <T>(
  url: string,
  type: string,
  obj: any = undefined
): Promise<IStandardAPIResponse<T>> => {
  try {
    type = type.toUpperCase();
    if (type.toLowerCase() === "get" && obj) {
      var params = Object.keys(obj)
        .map(function (key) {
          return key + "=" + obj[key];
        })
        .join("&");
      url += "?" + params;
      obj = undefined;
    }

    // headers: {
    //   "Content-Type": "application/json; charset=utf-8",
    //   at: getToken(),
    //   "Access-Control-Allow-Origin": "*"
    // },
    let token = getToken();
    let res = await fetch(API_URL + url, {
      method: type.toUpperCase(),
      body: JSON.stringify(obj),

      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getToken()}`
      },
    });
    return await res.json();
  } catch (error) {
    console.group(`API ${type} Error`);
    console.error(error);
    console.groupEnd();
    throw error;
  }
};


export const isTokenExpired = () => {
  var token = getToken();
  if (token) {
    let user = parseJwt(token);
    var cur_time = new Date().getTime() / 1000;
    if (user && user.exp && cur_time < user.exp) {
      return false;
    }
    return true;
  } else {
    return true;
  }
};

export const removeNulls = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") {
      removeNulls(obj[key]);
    } else if (obj[key] === "" || obj[key] === null) {
      delete obj[key];
    }
  });
  return obj;
};

export function debounce<F extends (...params: any[]) => void>(
  fn: Function,
  delay: number
) {
  let timeoutID: any = null;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}

export const removeModal = () =>{
  let modal=$(".modal")
  if(modal){
    modal.modal("hide");
  } 
  let modalBackdrop=$(".modal-backdrop")
  if(modalBackdrop){
    modalBackdrop.remove();
  }  
   $("body").removeClass("modal-open");
}