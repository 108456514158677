import React from "react";
import { ToastContext } from "../common/ToastProvider";
import {
  IBoardDetails,
  IChapterDetails,
  IClassDetails,
  IEducatorDetails,
  ILessonDetails,
  IMediumDetails,
  ISubjectDetails,
  IVideosDetails,
} from "../../vm";
import { getAllMediums } from "../../services/MediumServices";
import { getAllBoards, getAllClasses } from "../../services/ClassService";
import { getAllSubjects } from "../../services/SubjectService";
import Loading from "../common/Loading";
import { getAllChapters } from "../../services/ChapterService";
import "../style.css";
import {
  addALesson,
  getAllLessons,
  getAllVideos,
  updateALesson,
} from "../../services/LessonService";
import { VIMEO_CREDENTIALS, LANGUAGES_DICT } from "../../Constant";
import ViewLesson from "./ViewLesson";
import { parseJwt, removeModal } from "../../services/UtilService";
import { Formik, FormikErrors } from "formik";
import NoData from "../common/NoData";
var Vimeo = require("vimeo").Vimeo;

export interface LessonsProps {}

const Lessons: React.FC<LessonsProps> = () => {
  // state
  const [file, setFile] = React.useState(undefined);
  const [state, setState] = React.useState({
    isLoading: false,
    allBoards: [],
    allMediums: [],
    allClasses: [],
    board_id: undefined,
    medium_id: undefined,
    class_id: undefined,
    allSubjects: [],
    subject_id: undefined,
    chapter_id: undefined,
    allChapters: [],
    onEdit: false,
    educators: [],
    lessons: [],
    educator_id: undefined,
    lessonObj: {
      lesson_name: "",
      lesson_id: undefined,
      description: "",
    },
    languages: [],
    showVideoPage: false,
    lesson: {},
    video_url: "",
    isSidebarOpen: false,
    boardDict: {},
    classDict: {},
    mediumDict: {},
    subjectDict: {},
    chapterDict: {},
  } as {
    isLoading: boolean;
    allBoards: IBoardDetails[];
    allMediums: IMediumDetails[];
    allClasses: IClassDetails[];
    board_id: number;
    medium_id: number;
    class_id: number;
    allSubjects: ISubjectDetails[];
    subject_id: number;
    chapter_id: number;
    allChapters: IChapterDetails[];
    onEdit: boolean;
    educators: IEducatorDetails[];
    lessons: IVideosDetails[];
    educator_id: number;
    lessonObj: {
      lesson_name: string;
      lesson_id: number;
      description: string;
    };
    languages: string[];
    showVideoPage: boolean;
    video_url: string;
    lesson: IVideosDetails;
    isSidebarOpen: boolean;
    boardDict: any;
    classDict: any;
    mediumDict: any;
    subjectDict: any;
    chapterDict: any;
  });

  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  // decoded token
  const preference = parseJwt();

  // hooks
  React.useEffect(() => {
    const asyncFunc = async () => {
      await getBoardDetails();
      document.querySelector(".sideBar")?.classList.add("showSideBar");
      document.querySelector("#main_containerin")?.classList.add("ml-300px");
    };
    asyncFunc();
  }, []);

  const getBoardDetails = async () => {
    setState({ ...state, isLoading: true });
    let result = await getAllBoards();
    if (result && result.status) {
      getClassDetails(result.data.getBoard);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting board details",
        "error"
      );
    }
  };

  const getClassDetails = async (allBoards: any) => {
    setState({ ...state, isLoading: true });
    let result = await getAllClasses();
    if (result && result.status) {
      getMediumDetails(allBoards, result.data);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting class details",
        "error"
      );
    }
  };

  const getMediumDetails = async (allBoards: any, allClasses: any) => {
    setState({ ...state, isLoading: true });

    let result = await getAllMediums();
    if (result && result.status) {
      let boardDict = {};
      let classDict = {};
      let mediumDict = {};
      allBoards?.forEach((b: any) => {
        boardDict[b.board_id] = b.board_name;
      });
      allClasses?.forEach((c: any) => {
        classDict[c.class_id] = c.class_name;
      });
      result.data?.getMedium?.forEach((m: any) => {
        mediumDict[m.medium_id] = m.medium;
      });

      let obj = {
        ...state,
        allBoards,
        allClasses,
        allMediums: result.data.getMedium,
        board_id: allBoards[0].board_id,
        medium_id: result.data.getMedium[0].medium_id,
        class_id: allClasses[0].class_id,
        boardDict,
        classDict,
        mediumDict,
        isLoading: false,
      };
      setState(obj);
      getSubjects(obj);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting medium details",
        "error"
      );
    }
  };

  const getSubjects = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }

    setState({ ...localState, isLoading: true });
    let result = await getAllSubjects({
      board_id: localState.board_id,
      medium_id: localState.medium_id,
      class_id: localState.class_id,
    });
    if (result && result.status) {
      // let subjectDict = {};
      // result.data !== ""
      //   ? result.data
      //   : [].forEach((s: any) => {
      //       subjectDict[s.subject_id] = s.subject;
      //     });
      // let obj;
      if (result.data?.length > 0) {
        let obj = {
          ...localState,
          allSubjects: result.data !== "" ? result.data : [],
          subject_id: result.data[0]?.subject_id,
          // subjectDict,
          isLoading: false,
        };
        setState(obj);
        getChapters(obj);
      } else {
        let obj = {
          ...localState,
          allSubjects: [],
          subject_id: undefined,
          allChapters: [],
          chapter_id: undefined,
          educators: [],
          educator_id: undefined,
          lessons: [],
          isLoading: false,
        };
        setState(obj);
        showToast("No subjects for this combination", "error");
      }
      // setState(obj)
    } else {
      setState({ ...localState, isLoading: false });
      showToast(result?.message || "Error while getting subjects", "error");
    }
  };

  const getChapters = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }

    setState({ ...localState, isLoading: true });
    let result = await getAllChapters({
      board_id: localState.board_id,
      medium_id: localState.medium_id,
      class_id: localState.class_id,
      subject_id: localState.subject_id,
    });
    if (result && result.status) {
      // let obj;
      if (result.data?.get_chapters.length > 0) {
        let chapterDict = {};
        result.data?.get_chapters.forEach((ch) => {
          chapterDict[ch.chapter_id] = ch.chapter_name;
        });
        let obj = {
          ...localState,
          allChapters: result.data.get_chapters || [],
          chapter_id: result.data.get_chapters[0]?.chapter_id,
          chapterDict,
          isLoading: false,
        };
        setState(obj);
        getLessons(obj);
      } else {
        let obj = {
          ...localState,
          allChapters: [],
          chapter_id: undefined,
          educators: [],
          educator_id: undefined,
          lessons: [],
          isLoading: false,
        };
        setState(obj);
        showToast("No chapters for this combination", "error");
      }
      // setState(obj);
    } else {
      setState({ ...localState, isLoading: false });
      showToast(result?.message || "Error while getting chapters", "error");
    }
  };

  const getLessons = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }
    setState({ ...localState, isLoading: true });
    let result = await getAllLessons({
      board_id: localState.board_id,
      medium_id: localState.medium_id,
      class_id: localState.class_id,
      subject_id: localState.subject_id,
      chapter_id: localState.chapter_id,
    });
    if (result && result.status) {
      let obj = {
        ...localState,
        educators: result.data?.educators_content
          ? result.data?.educators_content
          : [],
        educator_id: result.data?.educators_content
          ? result.data.educators_content[0]?.id
          : undefined,
        isLoading: false,
      };
      setState(obj);
      //   if (result.data?.educators_content?.length > 0) {
      //     getVideos(obj);
      //   } else {
      //     showToast("No educator found found", "error");
      //   }
      getVideos(obj);
    } else {
      setState({ ...localState, isLoading: false });
      showToast(result?.message || "Error while getting educators", "error");
    }
  };

  const getVideos = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState) {
      localState = { ...paramState };
    }
    setState({ ...localState, isLoading: true });
    let result = await getAllVideos({
      chapter_id: localState.chapter_id,
      educator_id:
        preference.role === "Admin" ? localState.educator_id : preference.id,
    });
    if (result && result.status) {
      setState({
        ...localState,
        lessons: result.data || [],
        isLoading: false,
      });
    } else {
      setState({ ...localState, isLoading: false });
      showToast(result?.message || "Error while getting lessons", "error");
    }
  };

  const handlePreferenceChange = async (e: any) => {
    let obj = {
      ...state,
      [e.target.name]: parseInt(e.target.value),
    };
    setState(obj);

    await getSubjects(obj);
  };

  const onSubjectChange = async (e: any) => {
    let obj = {
      ...state,
      subject_id: parseInt(e.target.value),
    };
    setState(obj);
    await getChapters(obj);
  };

  const onChapterChange = async (e: any) => {
    let obj = {
      ...state,
      chapter_id: parseInt(e.target.value),
    };
    setState(obj);
    await getLessons(obj);
  };

  const onEducatorChange = async (e: any) => {
    setState({
      ...state,
      educator_id: parseInt(e.target.value),
    });
    console.log("edu_id", e.target.value);
  };

  const onTextChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onSearch = async () => {
    console.log("state", state);
    if (state.subject_id === undefined) {
      showToast("Select a subject", "error");
    }
    if (state.chapter_id === undefined) {
      showToast("Select a chapter", "error");
    }
    await getVideos();
  };

  const uploadVideo = (
    lesson_name: string,
    lesson_id: number,
    description: string
    // languages: any[]
  ) => {
    console.log("upload");
    if (state.subject_id !== undefined) {
      if (state.chapter_id !== undefined) {
        if (state.video_url !== "") {
          setState({ ...state, isLoading: true });
          onAddLesson(
            state.video_url,
            lesson_name,
            lesson_id,
            description
            // languages
          );
        } else {
          if (file) {
            if (file.type.indexOf("video/") === 0) {
              var client = new Vimeo(
                VIMEO_CREDENTIALS.CLIENT_ID,
                VIMEO_CREDENTIALS.CLIENT_SECRET,
                VIMEO_CREDENTIALS.TOKEN
              );
              setState({ ...state, isLoading: true });
              client.upload(
                file,
                {},
                function (url) {
                  /// this videoId needs to be used for showing in iframe and send it to API in video_url for storing in DB
                  let videoId = url.split("/").pop();
                  console.log(
                    "File upload completed. Your Vimeo URI is:",
                    videoId
                  );
                  onAddLesson(
                    videoId,
                    lesson_name,
                    lesson_id,
                    description
                    // languages
                  );
                },
                function (bytesUploaded, bytesTotal) {
                  // var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                  // console.log(bytesUploaded, bytesTotal, percentage + "%");
                },
                function (error) {
                  console.log("Failed because: " + error);
                }
              );
            } else {
              showToast("Please select video only", "error");
            }
          } else {
            showToast("Please select video", "error");
          }
        }
      } else {
        showToast("Please select chapter", "error");
      }
    } else {
      showToast("Please select subject", "error");
    }
  };

  const onAddLesson = async (
    videoId: string,
    lesson_name: string,
    lesson_id: number,
    description: string
    // languages: any[]
  ) => {
    // setState({ ...state, isLoading: true });
    let result;
    if (state.lessonObj.lesson_id != undefined) {
      result = await updateALesson({
        board_id: state.board_id,
        class_id: state.class_id,
        medium_id: state.medium_id,
        subject_id: state.subject_id,
        chapter_id: state.chapter_id,
        lesson: lesson_name,
        lesson_id: lesson_id,
        description: description,
        languages: state.languages,
        video_url: videoId,
      });
    } else {
      result = await addALesson({
        board_id: state.board_id,
        class_id: state.class_id,
        medium_id: state.medium_id,
        subject_id: state.subject_id,
        chapter_id: state.chapter_id,
        lesson: lesson_name,
        description: description,
        languages: state.languages,
        video_url: videoId,
      });
    }

    if (result && result.status) {
      removeModal();
      let lessons: IVideosDetails[] = [...state.lessons];
      let index = lessons.findIndex(
        (lesson) => lesson.lesson_id === result.data?.lesson_id
      );
      if (index != -1) {
        lessons[index] = result.data;
        console.log("updated");
      } else {
        lessons.unshift(result.data);
        console.log("added");
      }
      console.log("state.lessons", state, lessons);

      setState({
        ...state,
        lessons,
        lessonObj: {
          lesson_name: "",
          lesson_id: undefined,
          description: "",
        },
        languages: [],
        video_url: "",
        isLoading: false,
        isSidebarOpen: false,
      });
      setFile(undefined);
      showToast(
        `Lesson ${
          state.lessonObj.lesson_id != undefined ? "updated" : "added"
        } successfully`,
        "success"
      );
    } else {
      setState({
        ...state,
        isLoading: false,
      });
      showToast(
        result?.message ||
          `Error while ${
            state.lessonObj.lesson_id != undefined ? "updating" : "adding"
          } lesson`,
        "error"
      );
    }
  };

  const onSelectLanguages = (e: any) => {
    let languages = [...state.languages];

    if (languages.includes(e.target.value) === false) {
      languages.push(e.target.value);
    } else {
      let index = languages.findIndex((l) => l === e.target.value);
      languages.splice(index, 1);
    }
    setState({
      ...state,
      languages,
    });
  };

  const showVideoPage = (
    lesson: IVideosDetails
    // educatorName: string,
    // educatorImageUrl: string
  ) => {
    setState({
      ...state,
      showVideoPage: true,
      lesson,
    });
  };

  const handleShowVideoPage = () => {
    setState({ ...state, showVideoPage: false });
  };

  const onEdit = (lesson: IVideosDetails) => {
    setState({
      ...state,
      lessonObj: {
        lesson_id: lesson.lesson_id,
        lesson_name: lesson.name,
        description: lesson.description,
      },
      languages: lesson?.languages ? lesson.languages : [],
      video_url: lesson.video_url,
      isSidebarOpen: true,
    });
    setFile(file);
    console.log("file", file);
  };

  const onEmptyFieldValue = () => {
    setState({
      ...state,
      lessonObj: {
        lesson_id: undefined,
        lesson_name: "",
        description: "",
      },
      languages: [],
      video_url: "",
      isSidebarOpen: true,
    });
    setFile(undefined);
  };

  let publishedLessons: IVideosDetails[];

  return (
    <React.Fragment>
      {state.isLoading && <Loading />}
      {!state.showVideoPage && (
        <div className="contentColm" style={{ marginTop: 80 }}>
          <div className="row space-between">
            <div>
              <h4 style={{ marginTop: 6 }} className="text-bold">
                Approved Lessons
              </h4>
            </div>
            <div>
              <div className="contmid">
                <button
                  id="startsession"
                  className="finishBtn"
                  type="button"
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={onEmptyFieldValue}
                >
                  Add Lesson
                </button>
              </div>
            </div>
          </div>

          <div
            className="row card-shadow-pb-10 space-around p-16"
            style={{ marginTop: 30 }}
          >
            <div className="col-sm-4">
              <div className="form-group">
                <label>Board</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={handlePreferenceChange}
                  value={state.board_id}
                  name="board_id"
                >
                  {state.allBoards &&
                    state.allBoards.map((board) => {
                      return (
                        <option key={board.board_id} value={board.board_id}>
                          {board.board_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Medium</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={handlePreferenceChange}
                  value={state.medium_id}
                  name="medium_id"
                >
                  {state.allMediums &&
                    state.allMediums.map((medium) => {
                      return (
                        <option key={medium.medium_id} value={medium.medium_id}>
                          {medium.medium}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Class</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={handlePreferenceChange}
                  value={state.class_id}
                  name="class_id"
                >
                  {state.allClasses &&
                    state.allClasses.map((cls) => {
                      return (
                        <option key={cls.class_id} value={cls.class_id}>
                          {"Class " + cls.class_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <label>Subject</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={onSubjectChange}
                  value={state.subject_id}
                >
                  {state.allSubjects && state.allSubjects.length > 0 ? (
                    state.allSubjects.map((sub) => {
                      return (
                        <option key={sub.subject_id} value={sub.subject_id}>
                          {sub.subject}
                        </option>
                      );
                    })
                  ) : (
                    <option>No subjects</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Chapter</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={onChapterChange}
                  value={state.chapter_id}
                >
                  {state.allChapters && state.allChapters.length > 0 ? (
                    state.allChapters.map((chapter) => {
                      return (
                        <option
                          key={chapter.chapter_id}
                          value={chapter.chapter_id}
                        >
                          {chapter.chapter_name}
                        </option>
                      );
                    })
                  ) : (
                    <option>No chapters</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Educator</label>
                <select
                  className="form-control"
                  // id=""
                  onChange={onEducatorChange}
                  value={state.educator_id}
                >
                  {preference.role === "Admin" ? (
                    state.educators && state.educators.length > 0 ? (
                      state.educators.map((edu) => {
                        return (
                          <option key={edu.id} value={edu.id}>
                            {edu.name}
                          </option>
                        );
                      })
                    ) : (
                      <option>No educators</option>
                    )
                  ) : (
                    <option value={preference.id}>{preference.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-sm-2" style={{ marginTop: 5 }}>
              <button
                // id="startsession"
                className="btn btn-outline-warning"
                type="button"
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
                onClick={onSearch}
              >
                Search
              </button>
            </div>
          </div>

          {state.lessons && state.lessons.length > 0 ? (
            ((publishedLessons = state.lessons.filter(
              (l) => l.ispublished !== null
            )),
            publishedLessons.length > 0 ? (
              publishedLessons.map((lesson: IVideosDetails) => {
                return (
                  <div className="vidoDetailClm" style={{ marginTop: 30 }}>
                    <div className="row">
                      <div
                        className="col-sm-3"
                        onClick={() => showVideoPage(lesson)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            lesson?.thumbnail
                              ? lesson.thumbnail
                              : "/images/thumb1.jpg"
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm-7">
                        <h5 className="text-bold">
                          {lesson?.name || "Default Title"}
                        </h5>
                        <div className="tagsLink">
                          {state.allBoards && (
                            <p>
                              {
                                // state.allBoards.find(
                                //   (b) => b.board_id === state.board_id
                                // )?.board_name
                                state.boardDict[state.board_id]
                              }
                            </p>
                          )}
                          {state.allMediums && (
                            <p>
                              {
                                // state.allMediums.find(
                                //   (m) => m.medium_id === state.medium_id
                                // )?.medium
                                state.mediumDict[state.medium_id]
                              }
                            </p>
                          )}
                          {state.allClasses && (
                            <p>
                              Class{" "}
                              {
                                // state.allClasses.find(
                                //   (c) => c.class_id === state.class_id
                                // )?.class_name
                                state.classDict[state.class_id]
                              }
                            </p>
                          )}
                          {state.allSubjects && (
                            <p>
                              {
                                state.allSubjects.find(
                                  (sub) => sub.subject_id === state.subject_id
                                )?.subject
                                // state.subjectDict[state.subject_id]
                              }
                            </p>
                          )}
                          {state.allChapters && (
                            <p>
                              {
                                // state.allChapters.find(
                                //   (c) => c.chapter_id === state.chapter_id
                                // )?.chapter_name
                                state.chapterDict[state.chapter_id]
                              }
                            </p>
                          )}
                        </div>
                        <p>{lesson.description}</p>
                      </div>
                      {(preference?.role === "Admin" ||
                        preference?.role === "admin") && (
                        <div className="col-sm-1">
                          <span
                            onClick={() => onEdit(lesson)}
                            data-toggle="modal"
                            data-target="#myModal"
                            title="Edit"
                            className="pencil-icon"
                          >
                            <i className="fa fa-pencil"></i>
                          </span>
                        </div>
                        // <div className="col-sm-1">
                        //   <button
                        //     type="button"
                        //     className="btn btn-primary btn-sm"
                        //     data-toggle="modal"
                        //     data-target="#myModal"
                        //     onClick={() => onEdit(lesson)}
                        //   >
                        //     Edit
                        //   </button>
                        // </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className="row card-shadow"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 30,
                }}
              >
                {/* <h5>No approved lessons for this combination</h5> */}
                <NoData msg="No approved lessons for this combination" />
              </div>
            ))
          ) : (
            <div
              className="row card-shadow"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              {/* <h5>No approved lessons for this combination</h5> */}
              <NoData msg="No approved lessons for this combination" />
            </div>
          )}
          {/* <div className="row" style={{ marginTop: 50 }}>
          <div className="col-sm-4"></div>
          <div className="col-sm-4 card-shadow">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Video Name</th>
                </tr>
              </thead>
              <tbody>
                {state.lessons && state.lessons.length > 0 ? (
                  state.lessons.map((lesson, index) => {
                    return (
                      <tr key={index}>
                        <td width={150}>{index + 1}</td>
                        <td>{lesson.video_title}</td>
                      </tr>
                    );
                  })
                ) : (
                  <h6
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <br />
                    No Videos Found for this combination
                    <br />
                  </h6>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-sm-3"></div>
        </div> */}

          {/* <div className="row" style={{ marginTop: 50 }}>
          <div className="col-sm-3"></div>
          <div className="col-sm-6">
            {state.allChapters && state.allChapters.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Chapter Name</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {state.allChapters.map((chapter, index: number) => {
                    return (
                      <tr key={index}>
                        <td width={300}>{chapter.chapter_name}</td>
                        <td width={200}>
                          <button
                            type="button"
                            className="editBtn"
                            // data-toggle="tooltip"
                            // data-placement="top"
                            // title="Edit Class"
                            onClick={() => onEdit(chapter, index)}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="deleteBtn"
                            // data-toggle="tooltip"
                            // data-placement="top"
                            // title="Delete Class"
                            onClick={() => onDelete(chapter.chapter_id, index)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Chapter found for this combination
              </h5>
            )}
          </div>
          <div className="col-sm-3"></div>
        </div> */}

          {state.isSidebarOpen && (
            <div
              className="modal right fade"
              id="myModal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myModalLabel"
              data-backdrop="static"
              data-keyboard="false"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="myModalLabel2">
                      {state.lessonObj.lesson_id != undefined
                        ? "Update Lesson"
                        : "Add Lesson"}
                    </h4>
                    <button
                      type="button"
                      className="close"
                      // data-dismiss="modal"
                      // aria-label="Close"
                      onClick={() => {
                        removeModal();
                        setState({
                          ...state,
                          lessonObj: {
                            ...state.lessonObj,
                            lesson_name: "",
                            lesson_id: undefined,
                            description: "",
                          },
                          languages: [],
                          isSidebarOpen: false,
                        });
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <Formik
                      enableReinitialize
                      initialValues={state.lessonObj}
                      validate={(values) => {
                        let errors: FormikErrors<typeof state.lessonObj> = {};
                        if (!values.lesson_name) {
                          errors.lesson_name = "This field is required";
                        }
                        if (!values.description) {
                          errors.description = "This field is required";
                        }
                        // if (state.languages.length <= 0) {
                        //   errors.languages = "Required";
                        // }
                        return errors;
                      }}
                      onSubmit={async (
                        values: typeof state.lessonObj,
                        { setSubmitting }
                      ) => {
                        await uploadVideo(
                          values.lesson_name,
                          values.lesson_id,
                          values.description
                          // values.languages
                        );
                        setSubmitting(false);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        handleSubmit,
                        values,
                        handleChange,
                        handleBlur,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>Board</label>
                                <select
                                  className="form-control"
                                  // id=""
                                  onChange={handlePreferenceChange}
                                  value={state.board_id}
                                  name="board_id"
                                >
                                  {state.allBoards &&
                                    state.allBoards.map((board) => {
                                      return (
                                        <option
                                          key={board.board_id}
                                          value={board.board_id}
                                        >
                                          {board.board_name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>

                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>Medium</label>
                                <select
                                  className="form-control"
                                  // id=""
                                  onChange={handlePreferenceChange}
                                  value={state.medium_id}
                                  name="medium_id"
                                >
                                  {state.allMediums &&
                                    state.allMediums.map((medium) => {
                                      return (
                                        <option
                                          key={medium.medium_id}
                                          value={medium.medium_id}
                                        >
                                          {medium.medium}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>Class</label>
                                <select
                                  className="form-control"
                                  // id=""
                                  onChange={handlePreferenceChange}
                                  value={state.class_id}
                                  name="class_id"
                                >
                                  {state.allClasses &&
                                    state.allClasses.map((cls) => {
                                      return (
                                        <option
                                          key={cls.class_id}
                                          value={cls.class_id}
                                        >
                                          {"Class " + cls.class_name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>Subject</label>
                                <select
                                  className="form-control"
                                  // id=""
                                  onChange={onSubjectChange}
                                  value={state.subject_id}
                                >
                                  {state.allSubjects &&
                                  state.allSubjects.length > 0 ? (
                                    state.allSubjects.map((sub) => {
                                      return (
                                        <option
                                          key={sub.subject_id}
                                          value={sub.subject_id}
                                        >
                                          {sub.subject}
                                        </option>
                                      );
                                    })
                                  ) : (
                                    <option>No subjects</option>
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>Chapter</label>
                                <select
                                  className="form-control"
                                  // id=""
                                  onChange={onChapterChange}
                                  value={state.chapter_id}
                                >
                                  {state.allChapters &&
                                  state.allChapters.length > 0 ? (
                                    state.allChapters.map((chapter) => {
                                      return (
                                        <option
                                          key={chapter.chapter_id}
                                          value={chapter.chapter_id}
                                        >
                                          {chapter.chapter_name}
                                        </option>
                                      );
                                    })
                                  ) : (
                                    <option>No chapters</option>
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <div>
                                  <label>Video</label>
                                </div>
                                {state.video_url !== "" ? (
                                  <div>
                                    <button
                                      type="button"
                                      className="close closeBtn"
                                      onClick={() =>
                                        setState({ ...state, video_url: "" })
                                      }
                                      //   style={{ background: "red" }}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div className="embed-responsive embed-responsive-16by9">
                                      <iframe
                                        className="embed-responsive-item"
                                        src={`https://player.vimeo.com/video/${state.video_url}`}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                      ></iframe>
                                    </div>
                                  </div>
                                ) : (
                                  <input
                                    type="file"
                                    onChange={(event) => {
                                      event.target.files &&
                                      event.target.files.length > 0
                                        ? setFile(event.target.files[0])
                                        : setFile(undefined);
                                    }}
                                    //   value={file}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label>Lesson Name</label>
                              <input
                                type="text"
                                name="lesson_name"
                                value={values.lesson_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                //   placeholder="Name of the Video"
                              />
                              {(errors.lesson_name &&
                                touched.lesson_name &&
                                errors.lesson_name) ||
                                ""}
                            </div>
                            <div className="col-sm-12">
                              <label>Description</label>
                              <textarea
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                              {(errors.description &&
                                touched.description &&
                                errors.description) ||
                                ""}
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>Languages</label>
                                <select
                                  className="form-control"
                                  onChange={onSelectLanguages}
                                  value={state.languages}
                                  multiple
                                >
                                  {["1", "2", "3", "4", "5", "6", "7", "8"].map(
                                    (langId) => {
                                      return (
                                        <option key={langId} value={langId}>
                                          {LANGUAGES_DICT[langId]}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                {/* {(errors.languages &&
                                  touched.languages &&
                                  errors.languages) ||
                                  ""} */}
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <button
                                id="startsession"
                                className="submitBtn"
                                type="submit"
                                // data-dismiss="modal"
                                // onClick={() => uploadVideo()}
                              >
                                {state.lessonObj.lesson_id != undefined
                                  ? "Update"
                                  : "Add"}
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {state.showVideoPage && (
        <ViewLesson
          videoObj={state.lesson}
          // educatorObj={state.educatorObj}
          handleShowVideoPage={handleShowVideoPage}
          // chapters={state.allChapters}\
          boardDict={state.boardDict}
          boardId={state.board_id}
          mediumDict={state.mediumDict}
          mediumId={state.medium_id}
          allSubjects={state.allSubjects}
          subjectId={state.subject_id}
          chapterDict={state.chapterDict}
          chapterId={state.chapter_id}
          // classes={state.allClasses}
          classDict={state.classDict}
          classId={state.class_id}
          isPublished={false}
        />
      )}
    </React.Fragment>
  );
};

export default Lessons;
