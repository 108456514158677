import {  IMediumDetails, IStandardAPIResponse } from "../vm";
import { httpClient } from "./UtilService";

export async function getAllMediums(): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`get_medium`, 'GET');
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function addAMedium(
    data: { medium: string }
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`create_medium`, 'PUT', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function updateAMedium(
    data: IMediumDetails
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`update_medium`, 'PATCH', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function deleteAMedium(
    data: { medium_id: number }
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`delete_medium`, 'DELETE', data);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}
